import {makeAutoObservable} from "mobx";

export default class SectionStore {
    constructor() {
        this._section = []
        this._selectedSection = {};
        this._selectedSectionWithProduct = {};
        this._adminSection = {};
        this._adminProductSection = {};
        makeAutoObservable(this);
    }

    setSection(section) {
        this._section = section;
    }

    setSelectedSection(section) {
        this._selectedSection = section
    }

    setSelectedSectionWithProduct(section) {
        this._selectedSectionWithProduct = section
    }

    setAdminSection(section) {
        this._adminSection = section
    }

    setAdminProductSection(section) {
        this._adminProductSection = section
    }

    get section() {
        return this._section;
    }

    get selectedSection() {
        return this._selectedSection;
    }

    get selectedSectionWithProduct() {
        return this._selectedSectionWithProduct;
    }

    get adminSection() {
        return this._adminSection;
    }

    get adminProductSection() {
        return this._adminProductSection;
    }
}