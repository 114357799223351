import {makeAutoObservable} from "mobx";
import PrintingCenter from "../pages/public/PrintingCenter";

export default class TypeStore {
    constructor() {
        this._type = [];
        this._typeWithSection = {};
        this._typeAll = {};
        this._typeWithSectionClothesByProduct = {};
        this._typeWithSectionAccessoriesByProduct = {};
        this._selectedType = {};
        this._adminType = {};
        this._adminProductType = {};
        makeAutoObservable(this);
    }

    setType(type) {
        this._type = type;
    }

    setTypeWithSection(type) {
        this._typeWithSection = type;
    }

    setTypeAll(type) {
        this._typeAll = type;
    }

    setTypeWithSectionClothesByProduct(type) {
        this._typeWithSectionClothesByProduct = type;
    }

    setTypeWithSectionAccessoriesByProduct(type) {
        this._typeWithSectionAccessoriesByProduct = type;
    }

    setSelectedType(type) {
        this._selectedType = type
    }

    setAdminType(type) {
        this._adminType = type
    }

    setAdminProductType(type) {
        this._adminProductType = type
    }

    get type() {
        return this._type;
    }
    get typeWithSection() {
        return this._typeWithSection;
    }
    get typeAll() {
        return this._typeAll;
    }
    get typeWithSectionClothesByProduct() {
        return this._typeWithSectionClothesByProduct;
    }
    get typeWithSectionAccessoriesByProduct() {
        return this._typeWithSectionAccessoriesByProduct;
    }

    get selectedType() {
        return this._selectedType;
    }

    get adminType() {
        return this._adminType;
    }

    get adminProductType() {
        return this._adminProductType;
    }
}