import React from 'react';
import {Link} from "react-router-dom";
import {PUBLIC_PRODUCT_ROUTE} from "../../utils/consts";

const ProductItem = ({data,size}) => {

    let addingSpace = (count) => {
        let array = String(count).split('')
        if (array.length === 4)
            array.splice(1, 0, " ")
        if (array.length === 5)
            array.splice(2, 0, " ")
        if (array.length === 6)
            array.splice(3, 0, " ")
        return array.join('')
    }

    // создаем объекты даты
    const now = new Date();
    const createdAt = new Date(data.createdAt);

    // вычитаем 3 месяца из текущей даты
    now.setMonth(now.getMonth() - 3);

    // проверяем, был ли товар создан менее 3 месяцев назад
    const isNewProduct = createdAt >= now;

    return (
        <Link to={PUBLIC_PRODUCT_ROUTE + "/" + data.url}
              className={`relative flex flex-col items-start gap-4 no-underline ${size} `} data={data}>
            {isNewProduct &&
                <div
                    className="absolute top-[12px] left-[12px] w-[50px] h-[50px] flex justify-center items-center text-black bg-white p-[12px] rounded-[50%] z-[2]">NEW</div>
            }
            <div className="w-full h-[365px] flex items-center justify-center bg-gray-200 group relative">
                <img loading="lazy" className="w-full h-full object-cover z-[1] hover-card group-hover:opacity-0"
                     src={process.env.REACT_APP_API_URL + 'product/' + data.image}
                     alt=""
                />
                <img loading="lazy"
                     className="w-full h-full object-cover absolute top-0 left-0 z-0 opacity-0 hover-card group-hover:opacity-100"
                     src={process.env.REACT_APP_API_URL + 'product/carousel/' + data.carousel.split('|')[0]}
                     alt=""
                />
            </div>
            <h3 className="text-[#707070] text-[1rem] m-0 font-light">{data.name}</h3>
            <div className="text-[#707070] text-[1.2rem]">{addingSpace(data.price)} ₽</div>
        </Link>
    );
};

export default ProductItem;