import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import {fetchOneProductByUrl} from "../../http/productAPI";
import {Link, useParams} from "react-router-dom";
import {
    CarouselProvider,
    Slider,
    Slide,
    ImageWithZoom, Dot, DotGroup
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import {useForm} from "react-hook-form";
import {Toast} from "flowbite-react";

const OneProduct = observer((data) => {
    const {product, cart} = useContext(Context)
    const {url} = useParams()
    const [countImages, setCountImages] = useState();

    const {register, handleSubmit, setValue, watch, formState: {errors}} = useForm();

    watch("size");
    watch("color");

    useEffect(() => {
        fetchOneProductByUrl(String(url)).then(data => product.setSelectedProduct(data))
    }, [url])

    useEffect(() => {
        if (product.selectedProduct.carousel)
            setCountImages(product.selectedProduct.carousel.split('|').length)
    }, [product.selectedProduct.carousel])

    let addingSpace = (count) => {
        let array = String(count).split('')
        if (array.length === 4)
            array.splice(1, 0, " ")
        if (array.length === 5)
            array.splice(2, 0, " ")
        if (array.length === 6)
            array.splice(3, 0, " ")
        return array.join('')
    }
    const onSubmit = async (data) => {
        try {
            const productToAdd = {
                productId: product.selectedProduct.id,
                productPrice: product.selectedProduct.price,
                size: data.size,
                color: data.color,
            };

            // Добавьте товар в корзину с использованием нового метода addToCart
            cart.addToCart(productToAdd);

            console.log(cart.cart)
        } catch (e) {
            alert(e);
        }
    }

    return (
        <div className="flex gap-3">
            {errors.size?.type === 'required' || errors.color?.type === 'required' ?
                <div className="flex flex-col gap-4 absolute top-[90px] right-[24px] z-10">
                    {errors.size?.type === 'required' &&
                        <Toast className="w-auto">
                            <div
                                className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                </svg>

                            </div>
                            <div className="mx-3 text-sm font-normal">
                                Выберете размер
                            </div>
                            <Toast.Toggle
                                className="flex item-center justify-center outline-none outline-0 border-none"/>
                        </Toast>
                    }
                    {errors.color?.type === 'required' &&
                        <Toast className="w-auto">
                            <div
                                className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                </svg>

                            </div>
                            <div className="mx-3 text-sm font-normal">
                                Выберете цвет
                            </div>
                            <Toast.Toggle
                                className="flex item-center justify-center outline-none outline-0 border-none"/>
                        </Toast>
                    }
                </div>
                :
                <></>
            }
            <div className="w-1/2">
                <CarouselProvider
                    visibleSlides={1}
                    totalSlides={countImages}
                    step={1}
                    dragStep={1}
                    naturalSlideWidth={400}
                    naturalSlideHeight={500}
                    className="flex gap-3 relative"
                >
                    <div className="w-[70px] flex flex-col gap-y-3">
                        {product.selectedProduct.carousel ?
                            product.selectedProduct.carousel.split('|').map((value, index) =>
                                <Dot
                                    key={index}
                                    slide={index}
                                    className="w-full h-[70px] p-0"
                                >
                                     <img loading="lazy"
                                        className="w-full h-full object-cover"
                                        src={process.env.REACT_APP_API_URL + 'product/carousel/' + value}
                                        alt=""
                                    />
                                </Dot>
                            )
                            :
                            null
                        }
                    </div>
                    <Slider
                        className="w-full"
                    >
                        {product.selectedProduct.carousel ?
                            product.selectedProduct.carousel.split('|').map((value, index) =>
                                <Slide
                                    key={index}
                                    index={index}
                                    className="flex justify-center items-center"
                                >
                                    <ImageWithZoom
                                        className="w-full h-full slider_bg_options"
                                        src={process.env.REACT_APP_API_URL + 'product/carousel/' + value}
                                    />
                                </Slide>
                            )
                            :
                            null
                        }
                    </Slider>
                </CarouselProvider>
            </div>
            <form className="w-1/2 flex flex-col gap-5" onSubmit={handleSubmit(onSubmit)}>
                <span>{product.selectedProduct.name}</span>
                <span className="text-[1.2rem] font-bold">{addingSpace(product.selectedProduct.price)} ₽</span>
                {product.selectedProduct.sizes && product.selectedProduct.sizes.length > 0 ?
                    <div className="flex flex-col gap-3">
                        <span className="">Размер:</span>
                        <div className="flex gap-3">
                            {product.selectedProduct.sizes.map((e) =>
                                <div key={e.id} className="flex justify-center items-center">
                                    <input
                                        id={'size-' + e.id}
                                        className="hidden peer"
                                        type="radio"
                                        name="size"
                                        value={e.id}
                                        {...(register('size', {required: true}))}
                                        aria-invalid={errors.size ? "true" : "false"}
                                    />
                                    <label
                                        htmlFor={'size-' + e.id}
                                        className=" border border-gray-300 border-solid p-3 peer-checked:border-black cursor-pointer"
                                    >
                                        {e.name}
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>
                    :
                    null
                }
                <button
                    type="submit"
                    className="p-3 text-white font-bold text-[1.2rem] bg-black border-none cursor-pointer"
                >
                    Добавить в карзину
                </button>
                <Link className="text-black" to="#">Подобрать размер</Link>
                {product.selectedProduct.colors && product.selectedProduct.colors.length > 0 ?
                    <div className="flex flex-col gap-3">
                        <span>Цвет:</span>
                        <div className="flex gap-3">
                            {product.selectedProduct.colors.map((e) =>
                                <div key={e.id} className="flex justify-center items-center">
                                    <input
                                        id={'color-' + e.id}
                                        className="hidden peer"
                                        type="radio"
                                        name="color"
                                        value={e.id}
                                        {...(register('color', {required: true}))}
                                        aria-invalid={errors.color ? "true" : "false"}
                                    />
                                    <label
                                        htmlFor={'color-' + e.id}
                                        className=" border border-gray-300 border-solid p-3 peer-checked:border-black cursor-pointer"
                                    >
                                        {e.name}
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>
                    :
                    null
                }
                <div className="flex flex-col text-styling"
                     dangerouslySetInnerHTML={{__html: product.selectedProduct.description}}></div>
            </form>
        </div>
    );
});

export default OneProduct;