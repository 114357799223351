import React, {useContext, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {Link} from "react-router-dom";
import {ADMIN_ROUTE, CREATE_TYPE_ROUTE, EDITE_TYPE_ROUTE} from "../../../utils/consts";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import {Context} from "../../../index";
import {delType, fetchType} from "../../../http/typeAPI";

const Type = observer(() => {
    const {type} = useContext(Context)

    useEffect(() => {
        fetchType().then(data => type.setType(data))
    }, [type])

    const deleteType = (id) => {
        delType(id).then(data => type.setType(data))
    }

    const columns = [
        {
            name: 'name',
            header: 'Название типа',
            defaultFlex: 2
        },
        {
            name: 'edit',
            header: '',
            defaultFlex: 2,
            render: ({data}) => <div className="flex items-center justify-center">
                <Link to={EDITE_TYPE_ROUTE + '/' + data.id} className="text-black">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"/>
                    </svg>
                </Link>
            </div>
        },
        {
            name: 'remove',
            header: '',
            defaultFlex: 2,
            render: ({data}) => <div className="flex items-center justify-center">
                <button
                    className="flex items-center justify-center text-black bg-transparent border-0 cursor-pointer"
                    onClick={() => deleteType(data.id)}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"/>
                    </svg>
                </button>
            </div>
        },
    ];

    const gridStyle = {minHeight: 550}
    return (
        <div
            className="flex flex-col gap-5 rounded-md border border-neutral-200 border-solid rounded-lg shadow-lg p-[24px]">
            <div className="flex items-center justify-between">
                <Link
                    to={ADMIN_ROUTE}
                    className="flex items-center border-0 text-white text-[0.875rem] bg-[#111111] py-[12px] px-[24px] rounded-[50px] no-underline"
                >
                    <span className="mr-[6px]">Назад</span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"/>
                    </svg>
                </Link>
                <h1 className="text-[1.3rem] m-0">Редактирование/Создание типов</h1>
                <Link
                    to={CREATE_TYPE_ROUTE}
                    className="flex items-center border-0 text-white text-[0.875rem] bg-[#111111] py-[12px] px-[24px] rounded-[50px] no-underline"
                >
                    <span className="mr-[6px]">Создать тип</span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6"/>
                    </svg>
                </Link>
            </div>

            <div className="">
                <ReactDataGrid
                    idProperty="id"
                    columns={columns}
                    dataSource={type.type}
                    style={gridStyle}
                />
            </div>
        </div>
    );
});

export default Type;