import {$authHost, $host} from "./index";

export const createProduct = async (formData) => {
    const {data} = await $authHost.post('api/product/create', formData)
    return data;
}
export const editProduct = async (formData) => {
    const {data} = await $authHost.post('api/product/edit', formData)
    return data;
}
export const delProduct = async (id) => {
    const {data} = await $authHost.get('api/product/del/'+ id)
    return data;
}
export const fetchProducts = async () => {
    const {data} = await $host.get('api/product')
    return data;
}
export const fetchChartsProducts = async () => {
    const {data} = await $host.get('api/product/charts')
    return data;
}
export const fetchArrivalsProduct = async () => {
    const {data} = await $host.get('api/product/arrivals')
    return data;
}
export const fetchProductsWithSection = async () => {
    const {data} = await $host.get('api/product/section')
    return data;
}
export const fetchOneProduct = async (id) => {
    const {data} = await $host.get('api/product/' + id)
    return data;
}
export const fetchOneProductByUrl = async (url) => {
    const {data} = await $host.get('api/product/byUrl/' + url)
    return data;
}