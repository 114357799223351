export const ADMIN_ROUTE = '/admin'
export const LOGIN_ROUTE = '/login'
export const REGISTRATION_ROUTE = '/registration'
export const BANNER_ROUTE = '/banner'
export const CREATE_BANNER_ROUTE = '/create-banner'
export const EDITE_BANNER_ROUTE = '/edite-banner'
export const COLLECTION_ROUTE = '/collection'
export const CREATE_COLLECTION_ROUTE = '/create-collection'
export const EDITE_COLLECTION_ROUTE = '/edite-collection'
export const PRODUCT_ROUTE = '/product'
export const CREATE_PRODUCT_ROUTE = '/create-product'
export const EDITE_PRODUCT_ROUTE = '/edite-product'
export const SECTION_ROUTE = '/section'
export const CREATE_SECTION_ROUTE = '/create-section'
export const EDITE_SECTION_ROUTE = '/edite-section'
export const TYPE_ROUTE = '/type'
export const CREATE_TYPE_ROUTE = '/create-type'
export const EDITE_TYPE_ROUTE = '/edite-type'
export const COLOR_ROUTE = '/color'
export const CREATE_COLOR_ROUTE = '/create-color'
export const EDITE_COLOR_ROUTE = '/edite-color'
export const SIZE_ROUTE = '/size'
export const CREATE_SIZE_ROUTE = '/create-size'
export const EDITE_SIZE_ROUTE = '/edite-size'
export const HOME_ROUTE = '/'
export const BASKET_ROUTE = '/basket'
export const PUBLIC_PRODUCT_ROUTE = '/products'
export const PUBLIC_COLLECTIONS_ROUTE = '/collections'
export const PUBLIC_SECTION_SOUVENIR_PRODUCTS_ROUTE = '/sections/souvenir_products'
export const PUBLIC_SECTION_PRINTING_CENTER_ROUTE = '/sections/printing_center'
export const PUBLIC_SECTION_BY_URL = '/section'
export const PUBLIC_CONTACT = '/contact'