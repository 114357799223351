import React from 'react';
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <div
            className="w-full relative bg-white shadow-[0_-10px_8px_rgba(0,0,0,0.04)] z-[2] flex flex-col items-center py-[12px] pt-[48px] mt-auto">
            <div className="w-8/12 flex justify-between items-start border-0 border-b-2 border-solid border-gray-300 pb-[12px] mb-[12px]">
                <div className="w-1/4 flex flex-col gap-3">
                    <h3 className="m-0">Подробнее о SPbSFTU.STORE</h3>
                    <Link to="" className="text-[#707070] no-underline hover:text-black">О нас</Link>
                    <Link to="" className="text-[#707070] no-underline hover:text-black">Контакты</Link>
                </div>
                <div className="w-1/4 flex flex-col gap-3">
                    <h3 className="m-0">Помощь</h3>
                    <Link to="" className="text-[#707070] no-underline hover:text-black">Связаться с нами</Link>
                    <Link to="" className="text-[#707070] no-underline hover:text-black">Подобрать размер</Link>
                    <Link to="" className="text-[#707070] no-underline hover:text-black">Доставка и оплата</Link>
                    <Link to="" className="text-[#707070] no-underline hover:text-black">Обмен и возврат</Link>
                    <Link to="" className="text-[#707070] no-underline hover:text-black">Рекомендации по уходу</Link>
                </div>
                <div className="w-1/4 flex flex-col gap-3">
                    <h3 className="m-0">Одежда</h3>
                    <Link to="" className="text-[#707070] no-underline hover:text-black">Худи и свитшоты</Link>
                    <Link to="" className="text-[#707070] no-underline hover:text-black">Футболки и майки</Link>
                    <Link to="" className="text-[#707070] no-underline hover:text-black">Штаны</Link>
                    <Link to="" className="text-[#707070] no-underline hover:text-black">Костюмы</Link>
                    <Link to="" className="text-[#707070] no-underline hover:text-black">Шорты</Link>
                    <Link to="" className="text-[#707070] no-underline hover:text-black">Рюкзаки</Link>
                    <Link to="" className="text-[#707070] no-underline hover:text-black">Сумки</Link>
                </div>
                <div className="w-1/4 flex flex-col gap-3">
                    <h3 className="m-0">Одежда</h3>
                    <Link to="" className="text-[#707070] no-underline hover:text-black">Худи и свитшоты</Link>
                    <Link to="" className="text-[#707070] no-underline hover:text-black">Футболки и майки</Link>
                    <Link to="" className="text-[#707070] no-underline hover:text-black">Штаны</Link>
                    <Link to="" className="text-[#707070] no-underline hover:text-black">Костюмы</Link>
                    <Link to="" className="text-[#707070] no-underline hover:text-black">Шорты</Link>
                    <Link to="" className="text-[#707070] no-underline hover:text-black">Рюкзаки</Link>
                    <Link to="" className="text-[#707070] no-underline hover:text-black">Сумки</Link>
                </div>
            </div>
            <div className="flex">
                <div className="text-[1rem] font-light text-gray-500">© SPbSFTU.STORE, 2023. Санкт-Петербургский государственный лесотехнический университет имени С. М. Кирова</div>
            </div>
        </div>
    );
};

export default Footer;