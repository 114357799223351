import React from 'react';
import {Link, useNavigate} from "react-router-dom";
import {EDITE_SIZE_ROUTE, SIZE_ROUTE} from "../../../utils/consts";
import {observer} from "mobx-react-lite";
import {useForm} from "react-hook-form";
import {Toast} from "flowbite-react";
import {createSize} from "../../../http/sizeAPI";

const Create = observer(() => {
    const {register, handleSubmit, formState: {errors}} = useForm();

    let navigate = useNavigate();

    const onSubmit = async (data) => {
        try {
            const formData = new FormData();
            formData.append('name', data.name)
            let id = await createSize(formData)
            navigate(EDITE_SIZE_ROUTE + '/' + id)
        } catch (e) {
            alert(e.response)
        }
    }

    return (
        <div
            className="flex flex-col gap-5 rounded-md border border-neutral-200 border-solid rounded-lg shadow-lg p-[24px]">
            <div className="flex items-center justify-between">
                <h1 className="text-[1.3rem] m-0">Создание размера</h1>
                <Link
                    to={SIZE_ROUTE}
                    className="flex items-center border-0 text-white text-[0.875rem] bg-[#111111] py-[12px] px-[24px] rounded-[50px] no-underline"
                >
                    Назад
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"/>
                    </svg>
                </Link>
            </div>
            {errors.name?.type === 'required'
                ?
                <div className="flex flex-col gap-4 absolute top-[90px] right-[24px] z-10">
                    {errors.name?.type === 'required' &&
                        <Toast className="w-auto">
                            <div
                                className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                </svg>

                            </div>
                            <div className="mx-3 text-sm font-normal">
                                Заполните поле "Название размера"
                            </div>
                            <Toast.Toggle
                                className="flex item-center justify-center outline-none outline-0 border-none"/>
                        </Toast>
                    }
                </div>
                :
                <></>
            }
            <form
                className="flex flex-col gap-4 border border-gray-200 border-solid rounded-mb p-[12px]"
                onSubmit={handleSubmit(onSubmit)}
            >
                <h3 className="m-0">Форма создания размера</h3>
                <hr className="w-full h-[1px] bg-black"/>

                <div className="w-full flex items-center gap-3">
                    <label htmlFor="name" className="w-1/5 text-[1.2rem]">Название размера</label>
                    <input
                        className="w-full rounded-md outline-none"
                        id="name"
                        type="text"
                        name="name"
                        {...(register('name', {required: true}))}
                        aria-invalid={errors.name ? "true" : "false"}
                    />
                </div>
                <hr className="w-full h-[1px] bg-black"/>

                <button
                    type="submit"
                    className="inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-[#707070] px-4 py-2 text-base font-medium text-white shadow-sm cursor-pointer no-underline hover:bg-[#989898]"
                >
                    Создать
                </button>
            </form>
        </div>
    );
});

export default Create;