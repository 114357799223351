import React from 'react';
import affordable_prices from "../../image/OurAdvantages/affordable-prices.svg";
import high_quality from "../../image/OurAdvantages/high-quality.svg";
import prompt_production from "../../image/OurAdvantages/prompt-production.svg";
import wide_range from "../../image/OurAdvantages/wide-range.svg";
const OurAdvantages = () => {
    return (
        <div className="flex justify-between">
            <div className="w-1/4 p-3 flex flex-col items-center gap-3 linear-wipe">
                <img src={wide_range} className="object-contain w-1/2 h-1/2" alt=""/>
                <div className="font-bold text-[1.2rem]">Широкий ассортимент</div>
                <div className="leading-[1rem]">У нас представлен огромный выбор сувениров на любой вкус и бюджет. От классических магнитов и кружек до эксклюзивных подарков.</div>
            </div>
            <div className="w-1/4 p-3 flex flex-col items-center gap-3 linear-wipe">
                <img src={high_quality} className="object-contain w-1/2 h-1/2" alt=""/>
                <div className="font-bold text-[1.2rem]">Высокое качество</div>
                <div className="leading-[1rem]">Мы используем современное оборудование и материалы, чтобы ваша полиграфия получилась максимально качественной.</div>
            </div>
            <div className="w-1/4 p-3 flex flex-col items-center gap-3 linear-wipe">
                <img src={prompt_production} className="object-contain w-1/2 h-1/2" alt=""/>
                <div className="font-bold text-[1.2rem]">Оперативное изготовление</div>
                <div className="leading-[1rem]">Благодаря отлаженным процессам мы быстро изготавливаем сувенирную и полиграфическую продукцию. Ваш заказ будет готов в самые сжатые сроки.</div>
            </div>
            <div className="w-1/4 p-3 flex flex-col items-center gap-3 linear-wipe">
                <img src={affordable_prices} className="object-contain w-1/2 h-1/2" alt=""/>
                <div className="font-bold text-[1.2rem]">Доступные цены</div>
                <div className="leading-[1rem]">Мы предлагаем оптимальное соотношение цены и качества на все виды продукции. А регулярные акции и скидки делают покупки еще выгоднее.</div>
            </div>
        </div>
    );
};

export default OurAdvantages;