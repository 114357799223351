import React from 'react';
import {Link} from "react-router-dom";
import product from "../../image/product.svg";
import banner from "../../image/banner.svg";
import swatch from "../../image/swatch.svg";
import section from "../../image/section.svg";
import types from "../../image/types.svg";
import dropper from "../../image/dropper.svg";
import size from "../../image/size.svg";
import gift from "../../image/gift.svg";
import {
    BANNER_ROUTE,
    COLLECTION_ROUTE,
    COLOR_ROUTE,
    PRODUCT_ROUTE,
    SECTION_ROUTE,
    SIZE_ROUTE,
    TYPE_ROUTE
} from "../../utils/consts";

const MenuCard = () => {
    return (
        <div className="grid grid-cols-6 gap-5">
            <Link to={BANNER_ROUTE} className="col-span-1 flex flex-col gap-3 items-center no-underline">
                <div className="w-full h-[240px] flex items-center justify-center bg-gray-200">
                    <img loading="lazy"
                        className="w-1/2 object-cover"
                        src={banner}
                        alt=""
                    />
                </div>
                <h3 className="text-[#707070] m-0 font-light">Баннер</h3>
            </Link>
            <Link to={COLLECTION_ROUTE} className="col-span-1 flex flex-col gap-3 items-center no-underline">
                <div className="w-full h-[240px] flex items-center justify-center bg-gray-200">
                    <img loading="lazy"
                        className="w-1/2 object-cover"
                        src={swatch}
                        alt=""
                    />
                </div>
                <h3 className="text-[#707070] m-0 font-light">Коллекции</h3>
            </Link>
            <Link to={SECTION_ROUTE} className="col-span-1 flex flex-col gap-3 items-center no-underline">
                <div className="w-full h-[240px] flex items-center justify-center bg-gray-200">
                    <img loading="lazy"
                        className="w-1/2 object-cover"
                        src={section}
                        alt=""
                    />
                </div>
                <h3 className="text-[#707070] m-0 font-light">Разделы</h3>
            </Link>
            <Link to={PRODUCT_ROUTE} className="col-span-1 flex flex-col gap-3 items-center no-underline">
                <div className="w-full h-[240px] flex items-center justify-center bg-gray-200">
                    <img loading="lazy"
                        className="w-1/2 object-cover"
                        src={product}
                        alt=""
                    />
                </div>
                <h3 className="text-[#707070] m-0 font-light">Товары</h3>
            </Link>
            <Link to={TYPE_ROUTE} className="col-span-1 flex flex-col gap-3 items-center no-underline">
                <div className="w-full h-[240px] flex items-center justify-center bg-gray-200">
                    <img loading="lazy"
                        className="w-1/2 object-cover"
                        src={types}
                        alt=""
                    />
                </div>
                <h3 className="text-[#707070] m-0 font-light">Типы продукта</h3>
            </Link>
            <Link to={COLOR_ROUTE} className="col-span-1 flex flex-col gap-3 items-center no-underline">
                <div className="w-full h-[240px] flex items-center justify-center bg-gray-200">
                    <img loading="lazy"
                        className="w-1/2 object-cover"
                        src={dropper}
                        alt=""
                    />
                </div>
                <h3 className="text-[#707070] m-0 font-light">Цвета</h3>
            </Link>
            <Link to={SIZE_ROUTE} className="col-span-1 flex flex-col gap-3 items-center no-underline">
                <div className="w-full h-[240px] flex items-center justify-center bg-gray-200">
                    <img loading="lazy"
                        className="w-1/2 object-cover"
                        src={size}
                        alt=""
                    />
                </div>
                <h3 className="text-[#707070] m-0 font-light">Размеры</h3>
            </Link>
            <Link to="" className="col-span-1 flex flex-col gap-3 items-center no-underline">
                <div className="w-full h-[240px] flex items-center justify-center bg-gray-200">
                    <img loading="lazy"
                        className="w-1/2 object-cover"
                        src={gift}
                        alt=""
                    />
                </div>
                <h3 className="text-[#707070] m-0 font-light">Промо-код</h3>
            </Link>
        </div>
    );
};

export default MenuCard;