import React from 'react';
import MenuCard from "../../components/admin/MenuCard";

const Dashboard = () => {
    return (
        <div className="w-full flex flex-col gap-5">
            <MenuCard/>
        </div>
    );
};

export default Dashboard;