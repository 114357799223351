import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import {fetchProductsWithSection} from "../../http/productAPI";
import ProductList from "../../components/AllProduct/ProductList";
import FilterProduct from "../../components/AllProduct/FilterProduct";
import {fetchSection} from "../../http/sectionAPI";

const AllProduct = observer(() => {
    const { product, section } = useContext(Context);
    const [selectedSection, setSelectedSection] = useState(null);

    useEffect(() => {
        fetchProductsWithSection().then((data) => product.setProductWithSection(data));
    }, [product]);

    useEffect(() => {
        fetchSection().then((data) => section.setSection(data));
    }, [section]);

    // функция для фильтрации продуктов по выбранной секции
    const filteredProducts = selectedSection
        ? product.productWithSection.filter((p) =>
            p.sections.some((s) => s.id === selectedSection.id)
        )
        : product.productWithSection;
    return (
        <div className="w-full flex flex-col gap-5">
            <FilterProduct
                sections={section.section}
                selectedSection={selectedSection}
                onSectionSelect={(section) => setSelectedSection(section)}
            />
            <ProductList products={filteredProducts} />
        </div>
    );
});

export default AllProduct;