import React from 'react';
import carousel1 from "../../image/carousel-1.svg";
import {Link} from "react-router-dom";

const OfflinePlace = () => {
    return (
        <div className="h-[430px] flex bg-gray-200">
            <div className="w-5/12 h-full flex flex-col items-start gap-8 py-[48px] pl-[48px]">
                <div className="text-[2.3rem] text-black">Приходите к нам<br/>в магазин в Санкт-Петербурге</div>
                <div className="text-[1.3rem] text-[#707070]">
                    Здесь должен быть адрес<br/>
                    Открыто с 11:00 до 22:00, будни<br/>
                    м. Лесная<br/>
                    +7 (999) 999-99-99 (WhatsApp)
                </div>
                <Link to=""
                    className="border-[1px] border-solid border-[#707070] text-[#707070] text-[0.875rem] no-underline bg-transparent py-[12px] px-[24px] rounded-[50px]">
                    Подробнее
                </Link>
            </div>
            <div className="w-7/12 h-full bg-gray-300">
                <img loading="lazy"
                    className="w-full h-full object-cover"
                    src={carousel1}
                    alt=""
                />
            </div>
        </div>
    );
};

export default OfflinePlace;