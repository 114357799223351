import React, {useContext, useEffect} from 'react';
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {fetchType} from "../../http/typeAPI";
import no_img from "../../image/no-image.svg";

const TypeCard = observer(() => {
    const {type} = useContext(Context)

    useEffect(() => {
        fetchType().then(data => type.setTypeAll(data))
    }, [type])

    return (
        <div className="grid grid-cols-3 gap-5">
            {Array.isArray(type.typeAll) && type.typeAll.map(data =>
                    <div key={data.id} className="col-span-1 h-[500px]">
                        <img className="w-full object-cover h-full" src={data.image ? process.env.REACT_APP_API_URL + 'type/' + data.image : no_img} alt=""/>
                        <div className="">{data.name}</div>
                    </div>
                )
            }
        </div>
    );
});

export default TypeCard;