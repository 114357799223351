import React, {useContext, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import {useParams} from "react-router-dom";
import {fetchOneSectionByUrl} from "../../http/sectionAPI";
import ProductList from "../../components/AllProduct/ProductList";

const OneSection = observer(() => {
    const { section } = useContext(Context);
    const { url } = useParams();

    useEffect(() => {
        fetchOneSectionByUrl(String(url)).then(data => section.setSelectedSectionWithProduct(data));
    }, [url]);

    const selectedSection = section.selectedSectionWithProduct;
    const products = section.selectedSectionWithProduct.products;
    return (
        <div className="flex flex-col gap-5">
            <h2 className="">{selectedSection ? selectedSection.name : null}</h2>
            {products ?
                <ProductList products={products}/>
                :
                null
            }
        </div>
    );
});

export default OneSection;