import {$authHost, $host} from "./index";

export const createSize = async (formData) => {
    const {data} = await $authHost.post('api/size/create', formData)
    return data;
}
export const editSize = async (formData) => {
    const {data} = await $authHost.post('api/size/edit', formData)
    return data;
}
export const delSize = async (id) => {
    const {data} = await $authHost.get('api/size/del/'+ id)
    return data;
}
export const fetchSize = async () => {
    const {data} = await $host.get('api/size')
    return data;
}
export const fetchOneSize = async (id) => {
    const {data} = await $host.get('api/size/' + id)
    return data;
}