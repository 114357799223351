import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import {fetchTypeWithSectionByProduct} from "../../http/typeAPI";
import FilterProductForTypeClothes from "../../components/Type/FilterProductForTypeClothes";
import ProductList from "../../components/AllProduct/ProductList";

const PrintingCenter = observer(() => {
    const { type } = useContext(Context);

    useEffect(() => {
        fetchTypeWithSectionByProduct(2).then((data) => type.setTypeWithSectionAccessoriesByProduct(data));
    }, []);

    const sectionWithProduct = type.typeWithSectionAccessoriesByProduct;
    const allTypes = Object.keys(sectionWithProduct);

    const [selectedType, setSelectedType] = useState(null);

    const filteredSections = selectedType ? sectionWithProduct[selectedType] : null;

    // Создайте массив всех продуктов
    const allProducts = Object.values(sectionWithProduct).flat();
    return (
        <div className="flex flex-col gap-5">
            <FilterProductForTypeClothes
                types={allTypes}
                selectedType={selectedType}
                onTypeSelect={(type) => setSelectedType(type)}
            />
            {filteredSections ? (
                <ProductList products={filteredSections.flat()} />
            ) : (
                // Отображаем все продукты, если фильтрация не применена
                <ProductList products={allProducts} />
            )}
        </div>
    );
});

export default PrintingCenter;