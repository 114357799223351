import {makeAutoObservable} from "mobx";

export default class ColorStore {
    constructor() {
        this._color = []
        this._selectedColor = {};
        this._adminColor = {};
        this._adminProductColor = {};
        makeAutoObservable(this);
    }

    setColor(color) {
        this._color = color;
    }

    setSelectedColor(color) {
        this._selectedColor = color
    }

    setAdminColor(color) {
        this._adminColor = color
    }

    setAdminProductColor(color) {
        this._adminProductColor = color
    }

    get color() {
        return this._color;
    }

    get selectedColor() {
        return this._selectedColor;
    }

    get adminColor() {
        return this._adminColor;
    }
    get adminProductColor() {
        return this._adminProductColor;
    }
}