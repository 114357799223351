import React, {useContext} from 'react';
import {Checkbox, Label, TextInput, Toast} from "flowbite-react";
import {observer} from "mobx-react-lite";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {Context} from "../index";
import {login} from "../http/userAPI";
import {ADMIN_ROUTE} from "../utils/consts";
import logo from "../image/logo.svg";

const Auth = observer(() => {
    const {register, handleSubmit, formState: {errors}} = useForm();
    const {user} = useContext(Context)

    let navigate = useNavigate();

    const onSubmit = async (data) => {
        try {
            data = await login(data)
            user.setIsUser(data)
            user.setIsAuth(true)
            navigate(ADMIN_ROUTE)
            window.location.reload();
        } catch (e) {
            alert(e.response)
        }
    };
    return (
        <div className="flex flex-col items-center mt-[48px]">
            {errors.email?.type === 'required' || errors.password?.type === 'required' ?
                <div className="flex flex-col gap-4 absolute top-[90px] right-[24px] z-10">
                    {errors.email?.type === 'required' &&
                        <Toast className="w-auto">
                            <div
                                className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                </svg>

                            </div>
                            <div className="mx-3 text-sm font-normal">
                                Заполните поле "Ваша почта"
                            </div>
                            <Toast.Toggle
                                className="flex item-center justify-center outline-none outline-0 border-none"/>
                        </Toast>
                    }
                    {errors.password?.type === 'required' &&
                        <Toast className="w-auto">
                            <div
                                className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                </svg>
                            </div>
                            <div className="mx-3 text-sm font-normal">
                                Заполните поле "Ваш пароль"
                            </div>
                            <Toast.Toggle
                                className="flex item-center justify-center outline-none outline-0 border-none"/>
                        </Toast>
                    }
                </div>
                :
                <></>
            }
            <div className="flex w-1/2 h-[500px] bg-[#4F8852] gap-5 items-stretch">
                <div className="w-1/2 h-full bg-[#5DA561] flex items-center">
                    <img loading="lazy"
                        className="w-full object-contain"
                        src={logo}
                        alt=""
                    />
                </div>
                <form className="w-1/2 h-full flex flex-col gap-5 justify-center items-center pr-5"
                      onSubmit={handleSubmit(onSubmit)}>
                    <div className="w-full flex flex-col text-white">
                        <label htmlFor="email">Ваша почта</label>
                        <input
                            className="border-[#707070] outline-0"
                            type="email"
                            {...register("email", {required: true})}
                            aria-invalid={errors.email ? "true" : "false"}
                        />
                    </div>
                    <div className="w-full flex flex-col text-white">
                        <label htmlFor="password">Ваш пароль</label>
                        <input
                            type="password"
                            {...register("password", {required: true})}
                            aria-invalid={errors.password ? "true" : "false"}
                        />
                    </div>
                    <div className="w-full flex items-center text-white">
                        <label htmlFor="">Запомнить меня</label>
                        <input type="checkbox"/>
                    </div>
                    <button
                        className="w-full bg-white text-black text-[2rem] border-0 shadow-md p-[12px] cursor-pointer"
                        type="submit">
                        Войти
                    </button>
                </form>
            </div>
        </div>
    )
        ;
});

export default Auth;