import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import ProductItem from "./ProductItem";

const ProductList = observer(() => {

    const {product} = useContext(Context)

    return (
        <div className="grid grid-cols-4 gap-x-5 gap-y-10">
            {product.chartsProduct && product.chartsProduct.length > 0 ?
                product.chartsProduct.map(data => <ProductItem key={data.id} data={data}/>)
                :
                null
            }
            {product.chartsProduct && product.chartsProduct.length > 0 ?
                product.chartsProduct.map(data => <ProductItem key={data.id} data={data}/>)
                :
                null
            }
            {product.chartsProduct && product.chartsProduct.length > 0 ?
                product.chartsProduct.map(data => <ProductItem key={data.id} data={data}/>)
                :
                null
            }
            {product.chartsProduct && product.chartsProduct.length > 0 ?
                product.chartsProduct.map(data => <ProductItem key={data.id} data={data}/>)
                :
                null
            }
        </div>
    );
});

export default ProductList;