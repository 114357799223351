import React from 'react';
import carousel1 from "../../image/carousel-1.svg";
import {Link} from "react-router-dom";

const Collection = () => {
    return (
        <div className="grid grid-cols-2 gap-5">
            <Link to="" className="h-[375px] col-span-1 flex gap-x-3 bg-gray-200 no-underline">
                <div className="w-7/12 flex flex-col items-start gap-8 py-[48px] pl-[48px]">
                    <div className="text-[2.3rem] text-black">Утеплённая коллекция</div>
                    <div className="text-[1.3rem] text-[#707070]">Линейка осень-зима<br/>Уникальная технология начёса<br/>13 проверенных цветов</div>
                    <button className="border-0 text-white text-[0.875rem] bg-[#111111] py-[12px] px-[24px] rounded-[50px]">Смотреть</button>
                </div>
                <div className="w-5/12 bg-gray-300">
                    <img loading="lazy"
                        className="w-full h-full object-cover"
                        src={carousel1}
                        alt=""
                    />
                </div>
            </Link>
            <Link to="" className="h-[375px] col-span-1 flex gap-x-3 bg-gray-200 no-underline">
                <div className="w-7/12 flex flex-col items-start gap-8 py-[48px] pl-[48px]">
                    <div className="text-[2.3rem] text-black">Утеплённая коллекция</div>
                    <div className="text-[1.3rem] text-[#707070]">Линейка осень-зима<br/>Уникальная технология начёса<br/>13 проверенных цветов</div>
                    <button className="border-0 text-white text-[0.875rem] bg-[#111111] py-[12px] px-[24px] rounded-[50px]">Смотреть</button>
                </div>
                <div className="w-5/12 bg-gray-300">
                    <img loading="lazy"
                        className="w-full h-full object-cover"
                        src={carousel1}
                        alt=""
                    />
                </div>
            </Link>
        </div>
    );
};

export default Collection;