import {$authHost, $host} from "./index";

export const createCollection = async (formData) => {
    const {data} = await $authHost.post('api/collection/create', formData)
    return data;
}
export const editCollection = async (formData) => {
    const {data} = await $authHost.post('api/collection/edit', formData)
    return data;
}
export const delCollection = async (id) => {
    const {data} = await $authHost.get('api/collection/del/'+ id)
    return data;
}
export const fetchCollection = async () => {
    const {data} = await $host.get('api/collection')
    return data;
}
export const fetchOneCollection = async (id) => {
    const {data} = await $host.get('api/collection/' + id)
    return data;
}
export const fetchOneCollectionWithUrlByProduct = async (url) => {
    const {data} = await $host.get('api/collection/byUrl/' + url)
    return data;
}