import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import UserStore from "./store/UserStore";
import ProductStore from "./store/ProductStore";
import SectionStore from "./store/SectionStore";
import CollectionStore from "./store/CollectionStore";
import TypeStore from "./store/TypeStore";
import ColorStore from "./store/ColorStore";
import SizeStore from "./store/SizeStore";
import BannerStore from "./store/BannerStore";
import CartStore from "./store/CartStore";

export const Context = createContext(null)

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Context.Provider value={{
        user: new UserStore(),
        banner: new BannerStore(),
        product: new ProductStore(),
        section: new SectionStore(),
        collection: new CollectionStore(),
        type: new TypeStore(),
        color: new ColorStore(),
        size: new SizeStore(),
        cart: new CartStore(),
    }}>
        <App />
    </Context.Provider>
);
