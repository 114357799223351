import {makeAutoObservable} from "mobx";

export default class CollectionStore {
    constructor() {
        this._collection = []
        this._selectedCollection = {};
        this._adminCollection = {};
        this._adminProductCollection = {};
        makeAutoObservable(this);
    }

    setCollection(collection) {
        this._collection = collection;
    }

    setSelectedCollection(collection) {
        this._selectedCollection = collection
    }

    setAdminCollection(collection) {
        this._adminCollection = collection
    }

    setAdminProductCollection(collection) {
        this._adminProductCollection = collection
    }

    get collection() {
        return this._collection;
    }

    get selectedCollection() {
        return this._selectedCollection;
    }

    get adminCollection() {
        return this._adminCollection;
    }

    get adminProductCollection() {
        return this._adminProductCollection;
    }
}