import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

const FilterProductForTypeClothes = observer(({ types, selectedType, onTypeSelect }) => {
    const [isFilterSelected, setIsFilterSelected] = useState(false);

    useEffect(() => {
        setIsFilterSelected(!!selectedType);
    }, [selectedType]);

    const clearFilter = () => {
        onTypeSelect(null);
    };

    return (
        <div className="w-full flex flex-wrap gap-3">
            {types.map((type) => (
                <button
                    key={type}
                    className={`bg-transparent border-gray-300 border-solid border-[1px] rounded-full p-2 text-[1rem] cursor-pointer font-normal ${
                        type === selectedType ? 'bg-gray-200' : ''
                    }`}
                    onClick={() => onTypeSelect(type)}
                >
                    {type}
                </button>
            ))}
            {isFilterSelected && (
                <button
                    className="ml-auto bg-white shadow-lg border-gray-300 border-solid border-[1px] rounded-full p-2 text-[1rem] cursor-pointer font-normal flex items-center gap-2"
                    onClick={clearFilter}
                >
                    Очистить фильтр
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            )}
        </div>
    );
});

export default FilterProductForTypeClothes;
