import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {EDITE_PRODUCT_ROUTE, PRODUCT_ROUTE} from "../../../utils/consts";
import {observer} from "mobx-react-lite";
import {useForm} from "react-hook-form";
import {Toast} from "flowbite-react";
import {createProduct} from "../../../http/productAPI";
import ReactQuill from "react-quill";

const Create = observer(() => {
    const {register, handleSubmit, setValue, watch, formState: {errors}} = useForm();

    const [multipleImages, setMultipleImages] = useState([]);
    const changeMultipleFiles = async (e) => {
        if (e.target.files) {
            const imageArray = Array.from(e.target.files).map((file) =>
                URL.createObjectURL(file)
            );
            setMultipleImages((prevImages) => prevImages.concat(imageArray));
        }
    }

    useEffect(() => {
        register('description', {required: true})
    }, [register]);

    const onEditorStateChange = (editorState) => {
        setValue("description", editorState);
    };

    let navigate = useNavigate();
    const onSubmit = async (data) => {
        try {
            const formData = new FormData();
            for (const [key, value] of Object.entries(data)) {
                formData.append(key, value)
            }
            for (const key of Object.keys(multipleImages)) {
                formData.append('carousel', data.carousel[key]);
            }
            formData.append("image", data.image[0]);
            let id = await createProduct(formData)
            navigate(EDITE_PRODUCT_ROUTE + '/' + id)
        } catch (e) {
            alert(e.response)
        }
    }

    const editorContent = watch("description");

    return (
        <div
            className="flex flex-col gap-5 border border-neutral-200 border-solid rounded-lg shadow-lg p-[24px]">
            <div className="flex items-center justify-between">
                <h1 className="text-[1.3rem] m-0">Создание товара</h1>
                <Link
                    to={PRODUCT_ROUTE}
                    className="flex items-center border-0 text-white text-[0.875rem] bg-[#111111] py-[12px] px-[24px] rounded-[50px] no-underline"
                >
                    Назад
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"/>
                    </svg>
                </Link>
            </div>
            {errors.name?.type === 'required'
            || errors.description?.type === 'required'
            || errors.price?.type === 'required'
            || errors.status?.type === 'required'
            || errors.top?.type === 'required'
            || errors.promotion?.type === 'required'
            || errors.arrivals?.type === 'required'
            || errors.image?.type === 'required'
            || errors.carousel?.type === 'required'
                ?
                <div className="flex flex-col gap-4 absolute top-[90px] right-[24px] z-10">
                    {errors.name?.type === 'required' &&
                        <Toast className="w-auto">
                            <div
                                className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                </svg>

                            </div>
                            <div className="mx-3 text-sm font-normal">
                                Заполните поле "Название товара"
                            </div>
                            <Toast.Toggle
                                className="flex item-center justify-center outline-none outline-0 border-none"/>
                        </Toast>
                    }
                    {errors.description?.type === 'required' &&
                        <Toast className="w-auto">
                            <div
                                className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                </svg>

                            </div>
                            <div className="mx-3 text-sm font-normal">
                                Заполните поле "Описание товара"
                            </div>
                            <Toast.Toggle
                                className="flex item-center justify-center outline-none outline-0 border-none"/>
                        </Toast>
                    }
                    {errors.price?.type === 'required' &&
                        <Toast className="w-auto">
                            <div
                                className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                </svg>
                            </div>
                            <div className="mx-3 text-sm font-normal">
                                Заполните поле "Цена товара (Руб)"
                            </div>
                            <Toast.Toggle
                                className="flex item-center justify-center outline-none outline-0 border-none"/>
                        </Toast>
                    }
                    {errors.status?.type === 'required' &&
                        <Toast className="w-auto">
                            <div
                                className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                </svg>
                            </div>
                            <div className="mx-3 text-sm font-normal">
                                Заполните поле "Статус товара"
                            </div>
                            <Toast.Toggle
                                className="flex item-center justify-center outline-none outline-0 border-none"/>
                        </Toast>
                    }
                    {errors.top?.type === 'required' &&
                        <Toast className="w-auto">
                            <div
                                className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                </svg>
                            </div>
                            <div className="mx-3 text-sm font-normal">
                                Заполните поле "Популярность товара"
                            </div>
                            <Toast.Toggle
                                className="flex item-center justify-center outline-none outline-0 border-none"/>
                        </Toast>
                    }
                    {errors.promotion?.type === 'required' &&
                        <Toast className="w-auto">
                            <div
                                className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                </svg>
                            </div>
                            <div className="mx-3 text-sm font-normal">
                                Заполните поле "Акция"
                            </div>
                            <Toast.Toggle
                                className="flex item-center justify-center outline-none outline-0 border-none"/>
                        </Toast>
                    }
                    {errors.arrivals?.type === 'required' &&
                        <Toast className="w-auto">
                            <div
                                className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                </svg>
                            </div>
                            <div className="mx-3 text-sm font-normal">
                                Заполните поле "Новый товар"
                            </div>
                            <Toast.Toggle
                                className="flex item-center justify-center outline-none outline-0 border-none"/>
                        </Toast>
                    }
                    {errors.image?.type === 'required' &&
                        <Toast className="w-auto">
                            <div
                                className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                </svg>
                            </div>
                            <div className="mx-3 text-sm font-normal">
                                Заполните поле "Картинка товара"
                            </div>
                            <Toast.Toggle
                                className="flex item-center justify-center outline-none outline-0 border-none"/>
                        </Toast>
                    }
                    {errors.carousel?.type === 'required' &&
                        <Toast className="w-auto">
                            <div
                                className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                </svg>
                            </div>
                            <div className="mx-3 text-sm font-normal">
                                Заполните поле "Карусель товара"
                            </div>
                            <Toast.Toggle
                                className="flex item-center justify-center outline-none outline-0 border-none"/>
                        </Toast>
                    }
                </div>
                :
                <></>
            }
            <form className="flex flex-col gap-4 border border-gray-200 border-solid rounded-mb p-[12px]"
                  onSubmit={handleSubmit(onSubmit)}>
                <h3 className="m-0">Форма создания товара</h3>
                <hr className="w-full h-[1px] bg-black"/>

                <div className="w-full flex items-center gap-3">
                    <label htmlFor="name" className="w-1/5 text-[1.2rem]">Название товара</label>
                    <input
                        className="w-full rounded-md outline-none"
                        id="name"
                        type="text"
                        name="name"
                        {...(register('name', {required: true}))}
                        aria-invalid={errors.name ? "true" : "false"}
                    />
                </div>
                <hr className="w-full h-[1px] bg-black"/>

                <div className="w-full flex flex-col gap-3">
                    <span className="w-full text-[1.2rem]">Описание товара</span>
                    <ReactQuill
                        theme="snow"
                        value={editorContent}
                        onChange={onEditorStateChange}
                        modules={
                            {
                                clipboard: {
                                    matchVisual: false
                                }
                            }
                        }
                    />
                </div>
                <hr className="w-full h-[1px] bg-black"/>

                <div className="w-full grid grid-cols-3 gap-3">
                    <div className="col-span-1 flex flex-col gap-3">
                        <label htmlFor="price" className="text-[1.2rem]">Цена товара (Руб)</label>
                        <input
                            className="rounded-md outline-none"
                            id="price"
                            type="number"
                            min={0}
                            name="price"
                            {...register("price", {required: true})}
                            aria-invalid={errors.price ? "true" : "false"}
                        />
                    </div>
                    <div className="col-span-1 flex flex-col items-center gap-3">
                        <span className="text-[1.2rem]">Статус товара</span>
                        <div className="w-full flex justify-evenly items-center gap-3">
                            <div className="flex items-center">
                            <input
                                className=""
                                id="active"
                                name="status"
                                type="radio"
                                value="1"
                                {...register("status", {required: true})}
                                aria-invalid={errors.status ? "true" : "false"}
                            />
                            <label htmlFor="active">Опубликовано</label>
                        </div>
                        <div className="flex items-center">
                            <input
                                className=""
                                id="disable"
                                name="status"
                                type="radio"
                                value="0"
                                {...register("status", {required: true})}
                                aria-invalid={errors.status ? "true" : "false"}
                            />
                            <label htmlFor="disable">Скрыто</label>
                        </div>
                        </div>
                    </div>
                    <div className="col-span-1 flex flex-col items-center gap-3">
                        <span className="text-[1.2rem]">Популярность товара</span>
                        <div className="w-full flex justify-evenly items-center gap-3">
                            <div className="flex items-center">
                            <input
                                id="activeTop"
                                name="top"
                                type="radio"
                                value="1"
                                {...register("top", {required: true})}
                                aria-invalid={errors.top ? "true" : "false"}
                            />
                            <label htmlFor="activeTop">Популярен</label>
                        </div>
                        <div className="flex items-center">
                            <input
                                id="disableTop"
                                name="top"
                                type="radio"
                                value="0"
                                {...register("top", {required: true})}
                                aria-invalid={errors.top ? "true" : "false"}
                            />
                            <label htmlFor="disableTop">Обычный</label>
                        </div>
                        </div>
                    </div>
                    <div className="col-span-1 flex flex-col items-center gap-3">
                        <span className="text-[1.2rem]">Акция</span>
                        <div className="w-full flex justify-evenly items-center gap-3">
                            <div className="flex items-center">
                            <input
                                id="activePromotion"
                                name="promotion"
                                type="radio"
                                value="1"
                                {...register("promotion", {required: true})}
                                aria-invalid={errors.promotion ? "true" : "false"}
                            />
                            <label htmlFor="activePromotion">Да</label>
                        </div>
                        <div className="flex items-center">
                            <input
                                id="disablePromotion"
                                name="promotion"
                                type="radio"
                                value="0"
                                {...register("promotion", {required: true})}
                                aria-invalid={errors.promotion ? "true" : "false"}
                            />
                            <label htmlFor="disablePromotion">Нет</label>
                        </div>
                        </div>
                    </div>
                    <div className="col-span-1 flex flex-col items-center gap-3">
                        <span className="text-[1.2rem]">Новый товар</span>
                        <div className="w-full flex justify-evenly items-center gap-3">
                            <div className="flex items-center">
                            <input
                                id="activeArrivals"
                                name="arrivals"
                                type="radio"
                                value="1"
                                {...register("arrivals", {required: true})}
                                aria-invalid={errors.arrivals ? "true" : "false"}
                            />
                            <label htmlFor="activeArrivals">Да</label>
                        </div>
                        <div className="flex items-center">
                            <input
                                id="disableArrivals"
                                name="arrivals"
                                type="radio"
                                value="0"
                                {...register("arrivals", {required: true})}
                                aria-invalid={errors.arrivals ? "true" : "false"}
                            />
                            <label htmlFor="disableArrivals">Нет</label>
                        </div>
                        </div>
                    </div>
                </div>
                <hr className="w-full h-[1px] bg-black"/>

                <label className="w-full flex flex-col gap-3">
                    <span className="w-1/5 text-[1.2rem]">Картинка товара</span>
                    <input
                        className="w-full rounded-md outline-none"
                        type="file"
                        name="image"
                        {...register("image", {required: true})}
                        aria-invalid={errors.image ? "true" : "false"}
                    />
                </label>
                <hr className="w-full h-[1px] bg-black"/>

                <label className="w-full flex flex-col gap-3">
                    <span className="w-1/5 text-[1.2rem]">Карусель товара</span>
                    <input
                        className="w-full rounded-md outline-none"
                        type="file"
                        multiple
                        name="carousel"
                        {...register("carousel", {required: true})}
                        onChange={changeMultipleFiles}
                    />
                </label>
                <hr className="w-full h-[1px] bg-black"/>

                <button
                    type="submit"
                    className="inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-[#707070] px-4 py-2 text-base font-medium text-white shadow-sm cursor-pointer no-underline hover:bg-[#989898]"
                >
                    Создать
                </button>
            </form>
        </div>
    );
});

export default Create;