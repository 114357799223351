import React, {useContext, useEffect} from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import {fetchBanner} from "../../http/bannerAPI";

const Banner = observer(() => {
    const {banner} = useContext(Context)

    useEffect(() => {
        fetchBanner().then(data => banner.setBanner(data))
    }, [banner])

    return (
        <AwesomeSlider
            className="h-full bg-none"
            bullets={false}
        >
            {banner.banner.map(data =>
                <div className="flex w-full h-full items-center justify-center bg-gray-200" key={data.id}>
                    <img className="w-full h-full object-cover"
                         src={process.env.REACT_APP_API_URL + 'banners/' + data.image} alt=""/>
                </div>
            )}
        </AwesomeSlider>
    );
});

export default Banner;