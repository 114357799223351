import React from 'react';
import {Link} from "react-router-dom";
import wk from "../image/wk.svg";
import telegram from "../image/telegram.svg";
import youtube from "../image/youtube.svg";
import rutube from "../image/rutube.svg";
import ok from "../image/ok.svg";

const SocialNetwork = () => {
    return (
        <div className="fixed-center-top flex flex-col gap-3 z-10 bg-white border border-neutral-200 border-solid rounded-lg shadow-lg p-[12px]">
            <Link to="" className="w-[50px] h-[50px] link-hover link-hover_scale-1_03">
                <img loading="lazy" className="w-full" src={wk} alt=""/>
            </Link>
            <Link to="" className="w-[50px] h-[50px] link-hover link-hover_scale-1_03">
                <img loading="lazy" className="w-full" src={telegram} alt=""/>
            </Link>
            <Link to="" className="w-[50px] h-[50px] link-hover link-hover_scale-1_03">
                <img loading="lazy" className="w-full" src={youtube} alt=""/>
            </Link>
            <Link to="" className="w-[50px] h-[50px] link-hover link-hover_scale-1_03">
                <img loading="lazy" className="w-full" src={rutube} alt=""/>
            </Link>
            <Link to="" className="w-[50px] h-[50px] link-hover link-hover_scale-1_03">
                <img loading="lazy" className="w-full" src={ok} alt=""/>
            </Link>
        </div>
    );
};

export default SocialNetwork;