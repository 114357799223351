import React from 'react';
import IonPhotoViewer from "@codesyntax/ionic-react-photo-viewer";

const PhotoViewer = ({img}) => {
    return (
        <div className="col-span-1 flex items-center justify-center object-cover relative">
            <IonPhotoViewer
                src={process.env.REACT_APP_API_URL + 'product/carousel/' + img}
                title="Image title"
            >
                <img loading="lazy"
                    className=""
                    alt="Image alt"
                    src={process.env.REACT_APP_API_URL + 'product/carousel/' + img}
                />
            </IonPhotoViewer>
        </div>
    );
};

export default PhotoViewer;