import {makeAutoObservable} from "mobx";

export default class BannerStore {
    constructor() {
        this._banner = []
        this._selectedBanner = {};
        this._adminBanner = {};
        makeAutoObservable(this);
    }

    setBanner(banner) {
        this._banner = banner;
    }

    setSelectedBanner(banner) {
        this._selectedBanner = banner
    }

    setAdminBanner(banner) {
        this._adminBanner = banner
    }

    get banner() {
        return this._banner;
    }

    get selectedBanner() {
        return this._selectedBanner;
    }

    get adminBanner() {
        return this._adminBanner;
    }
}