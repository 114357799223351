import {$authHost, $host} from "./index";

export const createColor = async (formData) => {
    const {data} = await $authHost.post('api/color/create', formData)
    return data;
}
export const editColor = async (formData) => {
    const {data} = await $authHost.post('api/color/edit', formData)
    return data;
}
export const delColor = async (id) => {
    const {data} = await $authHost.get('api/color/del/'+ id)
    return data;
}
export const fetchColor = async () => {
    const {data} = await $host.get('api/color')
    return data;
}
export const fetchOneColor = async (id) => {
    const {data} = await $host.get('api/color/' + id)
    return data;
}