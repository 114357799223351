import {$authHost, $host} from "./index";

export const createBanner = async (formData) => {
    const {data} = await $authHost.post('api/banner/create', formData)
    return data;
}
export const editBanner = async (formData) => {
    const {data} = await $authHost.post('api/banner/edit', formData)
    return data;
}
export const delBanner = async (id) => {
    const {data} = await $authHost.get('api/banner/del/'+ id)
    return data;
}
export const fetchBanner = async () => {
    const {data} = await $host.get('api/banner')
    return data;
}
export const fetchOneBanner = async (id) => {
    const {data} = await $host.get('api/banner/' + id)
    return data;
}