import React, {useContext} from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import {authRotes, publicRoutes} from "../routes";
import {HOME_ROUTE} from "../utils/consts";
import {Context} from "../index";

const AppRouter = () => {

    const {user} = useContext(Context);

    return (
        <div className="w-8/12 py-[24px] pb-[48px]">
            <Routes>
                {user.isAuth && authRotes.map(({path, Component}) =>
                    <Route key={path} path={path} element={<Component/>}/>
                )}
                {publicRoutes.map(({path, Component}) =>
                    <Route key={path} path={path} element={<Component/>} exact/>
                )}
                <Route path="*" element={<Navigate to={HOME_ROUTE} replace/>}/>
            </Routes>
        </div>
    );
};

export default AppRouter;