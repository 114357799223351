import {
    ADMIN_ROUTE,
    BANNER_ROUTE,
    COLLECTION_ROUTE,
    COLOR_ROUTE,
    CREATE_BANNER_ROUTE,
    CREATE_COLLECTION_ROUTE,
    CREATE_COLOR_ROUTE,
    CREATE_PRODUCT_ROUTE,
    CREATE_SECTION_ROUTE,
    CREATE_SIZE_ROUTE,
    CREATE_TYPE_ROUTE,
    EDITE_BANNER_ROUTE,
    EDITE_COLLECTION_ROUTE,
    EDITE_COLOR_ROUTE,
    EDITE_PRODUCT_ROUTE,
    EDITE_SECTION_ROUTE,
    EDITE_SIZE_ROUTE,
    EDITE_TYPE_ROUTE,
    HOME_ROUTE,
    LOGIN_ROUTE,
    PRODUCT_ROUTE,
    PUBLIC_COLLECTIONS_ROUTE, PUBLIC_CONTACT,
    PUBLIC_PRODUCT_ROUTE,
    PUBLIC_SECTION_PRINTING_CENTER_ROUTE,
    PUBLIC_SECTION_BY_URL,
    PUBLIC_SECTION_SOUVENIR_PRODUCTS_ROUTE,
    REGISTRATION_ROUTE,
    SECTION_ROUTE,
    SIZE_ROUTE,
    TYPE_ROUTE
} from "./utils/consts";

import Home from "./pages/Home";
import AllProduct from "./pages/public/AllProduct";
import OneProduct from "./pages/public/OneProduct";
import OneCollection from "./pages/public/OneCollection";
import SouvenirProducts from "./pages/public/SouvenirProducts";
import PrintingCenter from "./pages/public/PrintingCenter";

import Auth from "./pages/Auth";

import Dashboard from "./pages/admin/Dashboard";
import Registration from "./pages/admin/Registration";
import Banner from "./pages/admin/Banner/Banner";
import CreateBanner from "./pages/admin/Banner/Create";
import EditeBanner from "./pages/admin/Banner/Edite";
import Collection from "./pages/admin/Collection/Collection";
import CreateCollection from "./pages/admin/Collection/Create";
import EditeCollection from "./pages/admin/Collection/Edite";
import Product from "./pages/admin/Product/Product";
import CreateProduct from "./pages/admin/Product/Create";
import EditeProduct from "./pages/admin/Product/Edite";
import Section from "./pages/admin/Section/Section";
import CreateSection from "./pages/admin/Section/Create";
import EditeSection from "./pages/admin/Section/Edite";
import Type from "./pages/admin/Type/Type";
import CreateType from "./pages/admin/Type/Create";
import EditeType from "./pages/admin/Type/Edite";
import Color from "./pages/admin/Color/Color";
import CreateColor from "./pages/admin/Color/Create";
import EditeColor from "./pages/admin/Color/Edite";
import Size from "./pages/admin/Size/Size";
import CreateSize from "./pages/admin/Size/Create";
import EditeSize from "./pages/admin/Size/Edite";
import OneSection from "./pages/public/OneSection";
import Contact from "./pages/public/Contact";

export const authRotes = [
    {
        path: ADMIN_ROUTE,
        Component: Dashboard
    },
    {
        path: REGISTRATION_ROUTE,
        Component: Registration
    },
    {
        path: BANNER_ROUTE,
        Component: Banner
    },
    {
        path: CREATE_BANNER_ROUTE,
        Component: CreateBanner
    },
    {
        path: EDITE_BANNER_ROUTE + '/:id',
        Component: EditeBanner
    },
    {
        path: COLLECTION_ROUTE,
        Component: Collection
    },
    {
        path: CREATE_COLLECTION_ROUTE,
        Component: CreateCollection
    },
    {
        path: EDITE_COLLECTION_ROUTE + '/:id',
        Component: EditeCollection
    },
    {
        path: PRODUCT_ROUTE,
        Component: Product
    },
    {
        path: CREATE_PRODUCT_ROUTE,
        Component: CreateProduct
    },
    {
        path: EDITE_PRODUCT_ROUTE + '/:id',
        Component: EditeProduct
    },
    {
        path: SECTION_ROUTE,
        Component: Section
    },
    {
        path: CREATE_SECTION_ROUTE,
        Component: CreateSection
    },
    {
        path: EDITE_SECTION_ROUTE + '/:id',
        Component: EditeSection
    },
    {
        path: TYPE_ROUTE,
        Component: Type
    },
    {
        path: CREATE_TYPE_ROUTE,
        Component: CreateType
    },
    {
        path: EDITE_TYPE_ROUTE + '/:id',
        Component: EditeType
    },
    {
        path: COLOR_ROUTE,
        Component: Color
    },
    {
        path: CREATE_COLOR_ROUTE,
        Component: CreateColor
    },
    {
        path: EDITE_COLOR_ROUTE + '/:id',
        Component: EditeColor
    },
    {
        path: SIZE_ROUTE,
        Component: Size
    },
    {
        path: CREATE_SIZE_ROUTE,
        Component: CreateSize
    },
    {
        path: EDITE_SIZE_ROUTE + '/:id',
        Component: EditeSize
    },
]
export const publicRoutes = [
    {
        path: LOGIN_ROUTE,
        Component: Auth
    },
    {
        path: HOME_ROUTE,
        Component: Home
    },
    {
        path: PUBLIC_PRODUCT_ROUTE,
        Component: AllProduct
    },
    {
        path: PUBLIC_PRODUCT_ROUTE + '/:url',
        Component: OneProduct
    },
    {
        path: PUBLIC_COLLECTIONS_ROUTE + '/:url',
        Component: OneCollection
    },
    {
        path: PUBLIC_SECTION_SOUVENIR_PRODUCTS_ROUTE,
        Component: SouvenirProducts
    },
    {
        path: PUBLIC_SECTION_PRINTING_CENTER_ROUTE,
        Component: PrintingCenter
    },
    {
        path: PUBLIC_SECTION_BY_URL + '/:url',
        Component: OneSection
    },
    {
        path: PUBLIC_CONTACT,
        Component: Contact
    },
]