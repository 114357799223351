import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Link, useParams} from "react-router-dom";
import {PRODUCT_ROUTE} from "../../../utils/consts";
import {Toast} from "flowbite-react";
import {useForm} from "react-hook-form";
import {editProduct, fetchOneProduct} from "../../../http/productAPI";
import {Context} from "../../../index";
import ReactQuill from "react-quill";
import IonPhotoViewer from "@codesyntax/ionic-react-photo-viewer";
import carousel1 from "../../../image/carousel-1.svg";
import PhotoViewer from "../../../components/admin/PhotoViewer";
import {fetchSection} from "../../../http/sectionAPI";
import {fetchCollection} from "../../../http/collectionAPI";
import {fetchType} from "../../../http/typeAPI";
import {fetchSize} from "../../../http/sizeAPI";
import {fetchColor} from "../../../http/colorAPI";
import {Multiselect} from "multiselect-react-dropdown";


const Edite = observer(() => {

    const {product} = useContext(Context)
    const {collection} = useContext(Context)
    const {section} = useContext(Context)
    const {type} = useContext(Context)
    const {size} = useContext(Context)
    const {color} = useContext(Context)

    const {id} = useParams()

    const {register, handleSubmit, setValue, watch, formState: {errors}} = useForm();

    const [multipleImages, setMultipleImages] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [filteredSizes, setFilteredSizes] = useState([]);
    const [colors, setColors] = useState([]);
    const [filteredColors, setFilteredColors] = useState([]);

    const [loading, setLoading] = useState(true)

    const editorContent = watch("description");
    watch("sizes");
    watch("colors");

    useEffect(() => {
        register('description', {required: true})
    }, [register]);

    useEffect(() => {
        fetchOneProduct(id)
            .then(data => product.setSelectedAdminProduct(data))
            .finally(() => setLoading(false))
        fetchCollection().then(data => collection.setAdminProductCollection(data))
        fetchSection().then(data => section.setAdminProductSection(data))
        fetchType().then(data => type.setAdminProductType(data))

        fetchSize().then(data => size.setAdminProductSize(data))
            .then(() => {
                if (Array.isArray(size.adminProductSize)) {
                    const sizeArray = size.adminProductSize.map(e => ({
                        value: e.id,
                        key: e.name,
                    }))
                    setSizes(sizeArray)
                }
            })

        fetchColor().then(data => color.setAdminProductColor(data))
            .then(() => {
                if (Array.isArray(color.adminProductColor)) {
                    const colorArray = color.adminProductColor.map(e => ({
                        value: e.id,
                        key: e.name,
                    }))
                    setColors(colorArray)
                }
            })
    }, [id])

    useEffect(() => {
        setValue("id", product.selectedAdminProduct.id);
        setValue("name", product.selectedAdminProduct.name);
        setValue("description", product.selectedAdminProduct.description);
        setValue("price", product.selectedAdminProduct.price);
        setValue("status", product.selectedAdminProduct.status);
        setValue("top", product.selectedAdminProduct.top);
        setValue("promotion", product.selectedAdminProduct.promotion);
        setValue("arrivals", product.selectedAdminProduct.arrivals);

        if (product.selectedAdminProduct.collections && product.selectedAdminProduct.collections.length > 0) {
            setValue("collection", product.selectedAdminProduct.collections[0].id);
        } else {
            setValue("collection", null);
        }
        if (product.selectedAdminProduct.sections && product.selectedAdminProduct.sections.length > 0) {
            setValue("section", product.selectedAdminProduct.sections[0].id);
        } else {
            setValue("section", null);
        }
        if (product.selectedAdminProduct.types && product.selectedAdminProduct.types.length > 0) {
            setValue("type", product.selectedAdminProduct.types[0].id);
        } else {
            setValue("type", null);
        }

        if (product.selectedAdminProduct.sizes !== undefined && product.selectedAdminProduct.sizes.length > 0) {
            let filteredSizesArr = []
            product.selectedAdminProduct.sizes.map((e) => {
                filteredSizesArr.push(
                    {
                        value: e.id,
                        key: e.name,
                    }
                )
            })
            setFilteredSizes(filteredSizesArr)
            setValue("sizes", filteredSizesArr);
        } else {
            setFilteredSizes(null)
            setValue("sizes", undefined);
        }

        if (product.selectedAdminProduct.colors !== undefined && product.selectedAdminProduct.colors.length > 0) {
            let filteredColorsArr = []
            product.selectedAdminProduct.colors.map((e) => {
                filteredColorsArr.push(
                    {
                        value: e.id,
                        key: e.name,
                    }
                )
            })
            setFilteredColors(filteredColorsArr)
            setValue("colors", filteredColorsArr);
        } else {
            setFilteredColors(null)
            setValue("colors", undefined);
        }
    }, [product.selectedAdminProduct]);
    const onEditorStateChange = (editorState) => {
        setValue("description", editorState);
    };
    const onSizeSelectMultiChange = (selectState) => {
        setValue("sizes", selectState);
    };
    const onColorSelectMultiChange = (selectState) => {
        setValue("colors", selectState);
    };
    const changeMultipleFiles = async (e) => {
        if (e.target.files) {
            const imageArray = Array.from(e.target.files).map((file) =>
                URL.createObjectURL(file)
            );
            setMultipleImages((prevImages) => prevImages.concat(imageArray));
        }
    }

    const onSubmit = async (data) => {
        try {
            const formData = new FormData();
            for (const [key, value] of Object.entries(data)) {
                switch (key) {
                    case 'sizes':
                        let stringSizeVal = []
                        if (value) {
                            value.map((e) => {
                                stringSizeVal.push(e.value)
                            })
                        }
                        formData.append('size', stringSizeVal.join('|'))
                        break;
                    case 'colors':
                        let stringColorVal = []
                        if (value) {
                            value.map((e) => {
                                stringColorVal.push(e.value)
                            })
                        }
                        formData.append('color', stringColorVal.join('|'))
                        break;
                    default:
                        formData.append(key, value)
                }
            }
            for (const key of Object.keys(multipleImages)) {
                formData.append('carousel', data.carousel[key]);
            }
            formData.append("image", data.image[0]);
            let productData = await editProduct(formData)
            product.setAdminProduct(productData)
        } catch (e) {
            alert(e)
        }
    }

    if (loading) {
        return <div
            className="flex flex-col gap-5 border border-neutral-200 border-solid rounded-lg shadow-lg p-[24px]">
            <div className="flex items-center justify-between">
                <h1 className="text-[1.3rem] m-0">Редактирование товара</h1>
                <Link
                    to={PRODUCT_ROUTE}
                    className="flex items-center border-0 text-white text-[0.875rem] bg-[#111111] py-[12px] px-[24px] rounded-[50px] no-underline"
                >
                    Назад
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"/>
                    </svg>
                </Link>
            </div>
            <div className="flex flex-col gap-4 border border-gray-200 border-solid rounded-mb p-[12px] animate-pulse">
                <h3 className="m-0">Форма редактирования товара</h3>
                <hr className="w-full h-[1px] bg-black"/>
                <div className="w-full flex items-start gap-3">
                    <div className="w-1/2 flex flex-col gap-3">
                        <div className="w-full">
                            <label htmlFor="name" className="w-1/5 text-[1.2rem]">Название товара</label>
                            <div className="h-[40px] bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                        </div>
                        <div className="w-full">
                            <label htmlFor="price" className="w-1/5 text-[1.2rem]">Цена товара (Руб)</label>
                            <div className="h-[40px] bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                        </div>
                    </div>
                    <div className="w-1/2 flex flex-col gap-3">
                        <div className="w-full">
                            <span className="w-1/5 text-[1.2rem]">Выпор коллекции продукта</span>
                            <div className="h-[40px] bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                        </div>
                        <div className="w-full">
                            <span className="w-1/5 text-[1.2rem]">Выпор раздела продукта</span>
                            <div className="h-[40px] bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                        </div>
                        <div className="w-full">
                            <span className="w-1/5 text-[1.2rem]">Выпор типа продукта</span>
                            <div className="h-[40px] bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                        </div>
                    </div>
                </div>
                <hr className="w-full h-[1px] bg-black"/>

                <div className="w-full flex flex-col gap-3">
                    <span className="w-full text-[1.2rem]">Описание товара</span>
                    <div className="h-[40px] bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                </div>
                <hr className="w-full h-[1px] bg-black"/>

                <div className="w-full flex gap-3">
                    <div className="w-1/2 flex flex-col gap-3">
                        <span className="text-[1.2rem]">Статус товара</span>
                        <div className="flex items-center">
                            <div className="h-[40px] bg-gray-200 rounded-full dark:bg-gray-700 w-[40px]"></div>
                            <label htmlFor="active">Опубликовано</label>
                        </div>
                        <div className="flex items-center">
                            <div className="h-[40px] bg-gray-200 rounded-full dark:bg-gray-700 w-[40px]"></div>
                            <label htmlFor="disable">Скрыто</label>
                        </div>
                    </div>
                    <div className="w-1/2 flex flex-col gap-3">
                        <span className="text-[1.2rem]">Популярность товара</span>
                        <div className="flex items-center">
                            <div className="h-[40px] bg-gray-200 rounded-full dark:bg-gray-700 w-[40px]"></div>
                            <label htmlFor="activeTop">Популярен</label>
                        </div>
                        <div className="flex items-center">
                            <div className="h-[40px] bg-gray-200 rounded-full dark:bg-gray-700 w-[40px]"></div>
                            <label htmlFor="disableTop">Обычный</label>
                        </div>
                    </div>
                </div>
                <hr className="w-full h-[1px] bg-black"/>

                <label className="w-full flex flex-col gap-3">
                    <span className="w-1/5 text-[1.2rem]">Картинка товара</span>
                    <div className="h-[40px] bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                </label>
                <hr className="w-full h-[1px] bg-black"/>

                <label className="w-full flex flex-col gap-3">
                    <span className="w-1/5 text-[1.2rem]">Карусель товара</span>
                    <div className="h-[40px] bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                </label>
                <hr className="w-full h-[1px] bg-black"/>

                <button
                    type="submit"
                    className="inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-[#707070] px-4 py-2 text-base font-medium text-white shadow-sm cursor-pointer no-underline hover:bg-[#989898]"
                >
                    Изменить
                </button>
            </div>
        </div>
    }

    return (
        <div
            className="flex flex-col gap-5 border border-neutral-200 border-solid rounded-lg shadow-lg p-[24px]">
            <div className="flex items-center justify-between">
                <h1 className="text-[1.3rem] m-0">Редактирование товара</h1>
                <Link
                    to={PRODUCT_ROUTE}
                    className="flex items-center border-0 text-white text-[0.875rem] bg-[#111111] py-[12px] px-[24px] rounded-[50px] no-underline"
                >
                    Назад
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"/>
                    </svg>
                </Link>
            </div>
            {errors.name?.type === 'required'
            || errors.description?.type === 'required'
            || errors.price?.type === 'required'
            || errors.section?.type === 'required'
            || errors.type?.type === 'required'
            || errors.status?.type === 'required'
            || errors.top?.type === 'required'
            || errors.promotion?.type === 'required'
            || errors.arrivals?.type === 'required'
            || errors.image?.type === 'required'
            || errors.carousel?.type === 'required'
                ?
                <div className="flex flex-col gap-4 absolute top-[90px] right-[24px] z-10">
                    {errors.name?.type === 'required' &&
                        <Toast className="w-auto">
                            <div
                                className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                </svg>

                            </div>
                            <div className="mx-3 text-sm font-normal">
                                Заполните поле "Название товара"
                            </div>
                            <Toast.Toggle
                                className="flex item-center justify-center outline-none outline-0 border-none"/>
                        </Toast>
                    }
                    {errors.description?.type === 'required' &&
                        <Toast className="w-auto">
                            <div
                                className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                </svg>

                            </div>
                            <div className="mx-3 text-sm font-normal">
                                Заполните поле "Описание товара"
                            </div>
                            <Toast.Toggle
                                className="flex item-center justify-center outline-none outline-0 border-none"/>
                        </Toast>
                    }
                    {errors.price?.type === 'required' &&
                        <Toast className="w-auto">
                            <div
                                className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                </svg>
                            </div>
                            <div className="mx-3 text-sm font-normal">
                                Заполните поле "Цена товара (Руб)"
                            </div>
                            <Toast.Toggle
                                className="flex item-center justify-center outline-none outline-0 border-none"/>
                        </Toast>
                    }
                    {errors.section?.type === 'required' &&
                        <Toast className="w-auto">
                            <div
                                className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                </svg>
                            </div>
                            <div className="mx-3 text-sm font-normal">
                                Заполните поле "Выпор раздела продукта"
                            </div>
                            <Toast.Toggle
                                className="flex item-center justify-center outline-none outline-0 border-none"/>
                        </Toast>
                    }
                    {errors.type?.type === 'required' &&
                        <Toast className="w-auto">
                            <div
                                className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                </svg>
                            </div>
                            <div className="mx-3 text-sm font-normal">
                                Заполните поле "Выпор типа продукта"
                            </div>
                            <Toast.Toggle
                                className="flex item-center justify-center outline-none outline-0 border-none"/>
                        </Toast>
                    }
                    {errors.status?.type === 'required' &&
                        <Toast className="w-auto">
                            <div
                                className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                </svg>
                            </div>
                            <div className="mx-3 text-sm font-normal">
                                Заполните поле "Статус товара"
                            </div>
                            <Toast.Toggle
                                className="flex item-center justify-center outline-none outline-0 border-none"/>
                        </Toast>
                    }
                    {errors.top?.type === 'required' &&
                        <Toast className="w-auto">
                            <div
                                className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                </svg>
                            </div>
                            <div className="mx-3 text-sm font-normal">
                                Заполните поле "Популярность товара"
                            </div>
                            <Toast.Toggle
                                className="flex item-center justify-center outline-none outline-0 border-none"/>
                        </Toast>
                    }
                    {errors.promotion?.type === 'required' &&
                        <Toast className="w-auto">
                            <div
                                className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                </svg>
                            </div>
                            <div className="mx-3 text-sm font-normal">
                                Заполните поле "Акция"
                            </div>
                            <Toast.Toggle
                                className="flex item-center justify-center outline-none outline-0 border-none"/>
                        </Toast>
                    }
                    {errors.arrivals?.type === 'required' &&
                        <Toast className="w-auto">
                            <div
                                className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                </svg>
                            </div>
                            <div className="mx-3 text-sm font-normal">
                                Заполните поле "Новый товар"
                            </div>
                            <Toast.Toggle
                                className="flex item-center justify-center outline-none outline-0 border-none"/>
                        </Toast>
                    }
                    {errors.image?.type === 'required' &&
                        <Toast className="w-auto">
                            <div
                                className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                </svg>
                            </div>
                            <div className="mx-3 text-sm font-normal">
                                Заполните поле "Картинка товара"
                            </div>
                            <Toast.Toggle
                                className="flex item-center justify-center outline-none outline-0 border-none"/>
                        </Toast>
                    }
                    {errors.carousel?.type === 'required' &&
                        <Toast className="w-auto">
                            <div
                                className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                </svg>
                            </div>
                            <div className="mx-3 text-sm font-normal">
                                Заполните поле "Карусель товара"
                            </div>
                            <Toast.Toggle
                                className="flex item-center justify-center outline-none outline-0 border-none"/>
                        </Toast>
                    }
                </div>
                :
                <></>
            }
            <form className="flex flex-col gap-4 border border-gray-200 border-solid rounded-mb p-[12px]"
                  onSubmit={handleSubmit(onSubmit)}>
                <input
                    type="hidden"
                    {...register("id", {required: true})}
                    aria-invalid={errors.id ? "true" : "false"}
                />
                <h3 className="m-0">Форма редактирования товара</h3>
                <hr className="w-full h-[1px] bg-black"/>

                <div className="w-full flex items-start gap-3">
                    <div className="w-1/2 flex flex-col gap-3">
                        <div className="w-full">
                            <label htmlFor="name" className="w-1/5 text-[1.2rem]">Название товара</label>
                            <input
                                className="w-full rounded-md outline-none"
                                id="name"
                                type="text"
                                name="name"
                                {...(register('name', {required: true}))}
                                aria-invalid={errors.name ? "true" : "false"}
                            />
                        </div>
                        <div className="w-full">
                            <label htmlFor="price" className="w-1/5 text-[1.2rem]">Цена товара (Руб)</label>
                            <input
                                className="w-full rounded-md outline-none"
                                id="price"
                                type="number"
                                min={0}
                                name="price"
                                {...register("price", {required: true})}
                                aria-invalid={errors.price ? "true" : "false"}
                            />
                        </div>
                    </div>
                    <div className="w-1/2 flex flex-col gap-3">
                        <div className="w-full">
                            <span className="w-1/5 text-[1.2rem]">Выпор коллекции продукта</span>
                            <select
                                className="w-full rounded-md outline-none"
                                {...register("collection", {required: false})}
                                defaultValue=""
                            >
                                <option disabled value="">Выберете коллекцию продукта</option>
                                <option value="0">Нет коллекции</option>
                                {Array.isArray(collection.adminProductCollection) && collection.adminProductCollection.map((e) =>
                                    <option key={e.id} value={e.id}>{e.name}</option>)}
                            </select>
                        </div>
                        <div className="w-full">
                            <span className="w-1/5 text-[1.2rem]">Выпор раздела продукта</span>
                            <select
                                className="w-full rounded-md outline-none"
                                {...register("section", {required: true})}
                                aria-invalid={errors.section ? "true" : "false"}
                                defaultValue=""
                            >
                                <option disabled value="">Выберете раздел продукта</option>
                                {Array.isArray(section.adminProductSection) && section.adminProductSection.map((e) =>
                                    <option key={e.id} value={e.id}>{e.name}</option>)}
                            </select>
                        </div>
                        <div className="w-full">
                            <span className="w-1/5 text-[1.2rem]">Выпор типа продукта</span>
                            <select
                                className="w-full rounded-md outline-none"
                                {...register("type", {required: true})}
                                aria-invalid={errors.type ? "true" : "false"}
                                defaultValue=""
                            >
                                <option disabled value="">Выберете тип продукта</option>
                                {Array.isArray(type.adminProductType) && type.adminProductType.map((e) => <option
                                    key={e.id} value={e.id}>{e.name}</option>)}
                            </select>
                        </div>
                        <div className="w-full flex flex-col gap-5">
                            <span className="w-full text-[1.2rem]">Выпор размера</span>
                            <Multiselect
                                displayValue="key"
                                onKeyPressFn={function noRefCheck() {
                                }}
                                onRemove={onSizeSelectMultiChange}
                                onSelect={onSizeSelectMultiChange}
                                options={sizes}
                                selectedValues={filteredSizes}
                                placeholder="Выберите размер"
                            />
                        </div>
                        <div className="w-full flex flex-col gap-5">
                            <span className="w-full text-[1.2rem]">Выпор цвета</span>
                            <Multiselect
                                displayValue="key"
                                onKeyPressFn={function noRefCheck() {
                                }}
                                onRemove={onColorSelectMultiChange}
                                onSelect={onColorSelectMultiChange}
                                options={colors}
                                selectedValues={filteredColors}
                                placeholder="Выберите цвет"
                            />
                        </div>
                    </div>
                </div>
                <hr className="w-full h-[1px] bg-black"/>

                <div className="w-full flex flex-col gap-3">
                    <span className="w-full text-[1.2rem]">Описание товара</span>
                    <ReactQuill
                        theme="snow"
                        value={editorContent}
                        onChange={onEditorStateChange}
                        modules={
                            {
                                clipboard: {
                                    matchVisual: false
                                }
                            }
                        }
                    />
                </div>
                <hr className="w-full h-[1px] bg-black"/>

                <div className="w-full grid grid-cols-2 gap-3">
                    <div className="col-span-1 flex flex-col items-center gap-3">
                        <span className="text-[1.2rem]">Статус товара</span>
                        <div className="w-full flex items-center justify-evenly gap-3">
                            <div className="flex items-center">
                                <input
                                    className=""
                                    id="active"
                                    name="status"
                                    type="radio"
                                    value="1"
                                    {...register("status", {required: true})}
                                    aria-invalid={errors.status ? "true" : "false"}
                                />
                                <label htmlFor="active">Опубликовано</label>
                            </div>
                            <div className="flex items-center">
                                <input
                                    className=""
                                    id="disable"
                                    name="status"
                                    type="radio"
                                    value="0"
                                    {...register("status", {required: true})}
                                    aria-invalid={errors.status ? "true" : "false"}
                                />
                                <label htmlFor="disable">Скрыто</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1 flex flex-col items-center gap-3">
                        <span className="text-[1.2rem]">Популярность товара</span>
                        <div className="w-full flex items-center justify-evenly gap-3">
                            <div className="flex items-center">
                                <input
                                    id="activeTop"
                                    name="top"
                                    type="radio"
                                    value="1"
                                    {...register("top", {required: true})}
                                    aria-invalid={errors.top ? "true" : "false"}
                                />
                                <label htmlFor="activeTop">Популярен</label>
                            </div>
                            <div className="flex items-center">
                                <input
                                    id="disableTop"
                                    name="top"
                                    type="radio"
                                    value="0"
                                    {...register("top", {required: true})}
                                    aria-invalid={errors.top ? "true" : "false"}
                                />
                                <label htmlFor="disableTop">Обычный</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1 flex flex-col items-center gap-3">
                        <span className="text-[1.2rem]">Акция</span>
                        <div className="w-full flex items-center justify-evenly gap-3">
                            <div className="flex items-center">
                                <input
                                    id="activePromotion"
                                    name="promotion"
                                    type="radio"
                                    value="1"
                                    {...register("promotion", {required: true})}
                                    aria-invalid={errors.promotion ? "true" : "false"}
                                />
                                <label htmlFor="activePromotion">Включить</label>
                            </div>
                            <div className="flex items-center">
                                <input
                                    id="disablePromotion"
                                    name="promotion"
                                    type="radio"
                                    value="0"
                                    {...register("promotion", {required: true})}
                                    aria-invalid={errors.promotion ? "true" : "false"}
                                />
                                <label htmlFor="disablePromotion">Выключить</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1 flex flex-col items-center gap-3">
                        <span className="text-[1.2rem]">Новый товар</span>
                        <div className="w-full flex items-center justify-evenly gap-3">
                            <div className="flex items-center">
                                <input
                                    id="activeArrivals"
                                    name="arrivals"
                                    type="radio"
                                    value="1"
                                    {...register("arrivals", {required: true})}
                                    aria-invalid={errors.new ? "true" : "false"}
                                />
                                <label htmlFor="activeArrivals">Да</label>
                            </div>
                            <div className="flex items-center">
                                <input
                                    id="disableArrivals"
                                    name="arrivals"
                                    type="radio"
                                    value="0"
                                    {...register("arrivals", {required: true})}
                                    aria-invalid={errors.new ? "true" : "false"}
                                />
                                <label htmlFor="disableArrivals">Нет</label>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="w-full h-[1px] bg-black"/>

                <label className="w-full flex flex-col gap-3">
                    <span className="w-1/5 text-[1.2rem]">Картинка товара</span>
                    <input
                        className="w-full rounded-md outline-none"
                        type="file"
                        name="image"
                        {...register("image", {required: false})}
                        aria-invalid={errors.image ? "true" : "false"}
                    />
                </label>
                <div className="w-full flex items-center">
                    <div className="w-1/4 object-cover">
                        <IonPhotoViewer
                            src={product.selectedAdminProduct.image ? process.env.REACT_APP_API_URL + 'product/' + product.selectedAdminProduct.image : carousel1}
                            title="Image title"
                        >
                            <img loading="lazy"
                                 className=""
                                 alt="Image alt"
                                 src={product.selectedAdminProduct.image ? process.env.REACT_APP_API_URL + 'product/' + product.selectedAdminProduct.image : carousel1}
                            />
                        </IonPhotoViewer>
                    </div>
                </div>
                <hr className="w-full h-[1px] bg-black"/>

                <label className="w-full flex flex-col gap-3">
                    <span className="w-1/5 text-[1.2rem]">Карусель товара</span>
                    <input
                        className="w-full rounded-md outline-none"
                        type="file"
                        multiple
                        name="carousel"
                        {...register("carousel", {required: false})}
                        onChange={changeMultipleFiles}
                    />
                </label>
                {product.selectedAdminProduct.carousel ?
                    <div className="w-full grid grid-cols-6 gap-3 items-center">
                        {product.selectedAdminProduct.carousel.split('|').map(e =>
                            <PhotoViewer key={e} img={e}/>
                        )}
                    </div>
                    :
                    null
                }
                <hr className="w-full h-[1px] bg-black"/>

                <button
                    type="submit"
                    className="inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-[#707070] px-4 py-2 text-base font-medium text-white shadow-sm cursor-pointer no-underline hover:bg-[#989898]"
                >
                    Изменить
                </button>
            </form>
        </div>
    );
});

export default Edite;