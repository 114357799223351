import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import ProductItem from "./ProductItem";

const TOTAL_COLS = 5;

const ProductList = observer(({products}) => {
    let rowCols = 0;
    let prevSize = null;

    let sizes = {
        small: 'row-span-1 col-span-1',
        // medium: 'row-span-2 col-span-1',
        large: 'row-span-1 col-span-2',
    }

    function getSize(sizes, rowCols) {
        let size = getRandomSize(sizes);

        if (rowCols + size.cols > TOTAL_COLS) {
            size = sizes.small;
        }

        if (prevSize === size) {
            size = getRandomSize(sizes);
        }

        return size;
    }


    function getRandomSize(sizes) {
        const keys = Object.keys(sizes);
        const randomKey = keys[Math.floor(Math.random() * keys.length)];

        return sizes[randomKey];
    }

    return (
        <div className="grid grid-cols-5 gap-5 grid-flow-dense">
            {products.map(data => {
                    const size = getSize(sizes, rowCols);

                    rowCols += size.cols;

                    if (rowCols === TOTAL_COLS) {
                        rowCols = 0;
                    }

                    prevSize = size;

                    return <ProductItem key={data.id} data={data} size={size}/>
                }
            )}
        </div>
    );
});

export default ProductList;