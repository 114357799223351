import {$authHost, $host} from "./index";

export const createSection = async (formData) => {
    const {data} = await $authHost.post('api/section/create', formData)
    return data;
}
export const editSection = async (formData) => {
    const {data} = await $authHost.post('api/section/edit', formData)
    return data;
}
export const delSection = async (id) => {
    const {data} = await $authHost.get('api/section/del/'+ id)
    return data;
}
export const fetchSection = async () => {
    const {data} = await $host.get('api/section')
    return data;
}
export const fetchSectionForFilterType = async () => {
    const {data} = await $host.get('api/section/filterType')
    return data;
}
export const fetchOneSection = async (id) => {
    const {data} = await $host.get('api/section/' + id)
    return data;
}
export const fetchOneSectionByUrl = async (url) => {
    const {data} = await $host.get('api/section/url/' + url)
    return data;
}