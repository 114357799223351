import React from 'react';
import {observer} from "mobx-react-lite";
import Slider from "../components/Banner/BannerList";
import SubMenuCard from "../components/HomePage/SubMenuCard";
import Charts from "../components/HomePage/Charts";
import Collection from "../components/HomePage/Collection";
import OfflinePlace from "../components/HomePage/OfflinePlace";
import TypeCard from "../components/HomePage/TypeCard";
import OurAdvantages from "../components/HomePage/OurAdvantages";

const Home = observer(() => {
    return (
        <div className="w-full flex flex-col gap-5">
            <Slider/>
            <OurAdvantages/>
            {/*<TypeCard/>*/}
            {/*<SubMenuCard/>*/}
            {/*<Charts/>*/}
            <Collection/>
            <OfflinePlace/>
        </div>
    );
});

export default Home;