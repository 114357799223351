import {makeAutoObservable} from "mobx";

export default class CartStore {
    constructor() {
        this._cart = [];
        this._totalPrice = 0;
        makeAutoObservable(this);
    }

    setCart(cart) {
        this._cart = cart;
    }

    get cart() {
        return this._cart;
    }

    get totalPrice() {
        return this._cart.reduce((total, item) => total + item.productPrice, 0);
    }

    addToCart(productToAdd) {
        this._cart.push(productToAdd);
    }
}