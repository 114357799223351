import {$authHost, $host} from "./index";
import jwt_decode from 'jwt-decode';

export const registration = async (formData) => {
    const {data} = await $host.post('api/user/registration', formData)
    localStorage.setItem('token', data.token)
    return jwt_decode(data.token)
}

export const login = async (formData) => {
    const {data} = await $host.post('api/user/login', formData)
    localStorage.setItem('token', data.token)
    return jwt_decode(data.token)
}

export const check = async () => {
    try {
        const { data } = await $authHost.get('api/user/auth');
        localStorage.setItem('token', data.token);
        return jwt_decode(data.token);
    } catch (error) {
        // Обработка ошибки
        console.error('Произошла ошибка аунтификации');
        throw error;
    }
}

export const fetchUser = async () => {
    const {data} = await $host.get('api/user')
    return data
}

export const fetchOneUser = async (id) => {
    const {data} = await $host.get('api/user/' + id)
    return data
}

export const checkFollowing = async (userId, friendId) => {
    const {data} = await $host.post('api/user/checkFollowing',{userId, friendId})
    return data
}

export const addFriend = async (userId, friendId) => {
    const {data} = await $host.post('api/user/addFriends', {userId, friendId})
    return data
}

export const deleteFriends = async (userId, friendId) => {
    const {data} = await $host.post('api/user/deleteFriends', {userId, friendId})
    return data
}
