import {makeAutoObservable} from "mobx";

export default class SizeStore {
    constructor() {
        this._size = []
        this._selectedSize = {};
        this._adminSize = {};
        this._adminProductSize = {};
        makeAutoObservable(this);
    }

    setSize(size) {
        this._size = size;
    }

    setSelectedSize(size) {
        this._selectedSize = size
    }

    setAdminSize(size) {
        this._adminSize = size
    }

    setAdminProductSize(size) {
        this._adminProductSize = size
    }

    get size() {
        return this._size;
    }

    get selectedSize() {
        return this._selectedSize;
    }

    get adminSize() {
        return this._adminSize;
    }

    get adminProductSize() {
        return this._adminProductSize;
    }
}