import basket from '../image/basket.svg'
import {observer} from "mobx-react-lite";
import {useContext, useState} from "react";
import {Context} from "../index";

const Cart = observer(() => {
    const { cart} = useContext(Context); // Получите доступ к контексту cart
    const [isHovered, setIsHovered] = useState(false);

    // Вычислите количество товаров в корзине (длину массива)
    const countCart = cart.cart.length;

    // Обработчик наведения на кнопку корзины
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <button
            className="fixed top-[80px] right-[100px] w-[100px] h-[100px] p-1 z-20 bg-transparent rounded-[50px] border-[1px] border-solid border-black cursor-pointer card-button_active"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className="w-full h-full flex justify-center items-center relative">
                <img loading="lazy"
                    className="h-full object-contain"
                    src={basket}
                    alt=""
                />
                <span className="bg-black h-[24px] w-[24px] text-white font-bold text-[1rem] absolute bottom-0 right-0 flex justify-center items-center rounded-[50px]">{countCart}</span>
                <span
                    className={`bg-black w-auto p-1 text-white font-bold text-[1rem] absolute top-[50%] left-[-40px] translate-x-[-50%] translate-y-[-50%] flex justify-center items-center ${
                        isHovered ? 'cart-prise-preview_active' : 'cart-prise-preview_disable'
                    }`}
                >{cart.totalPrice} ₽</span>
            </div>
        </button>
    );
});

export default Cart;