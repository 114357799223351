import React, {useContext, useEffect, useRef, useState} from 'react';
import {Context} from "../index";
import {
    ADMIN_ROUTE,
    HOME_ROUTE,
    PUBLIC_COLLECTIONS_ROUTE, PUBLIC_CONTACT,
    PUBLIC_PRODUCT_ROUTE,
    PUBLIC_SECTION_PRINTING_CENTER_ROUTE,
    PUBLIC_SECTION_SOUVENIR_PRODUCTS_ROUTE,
} from "../utils/consts";
import logo from '../image/logoBig.svg'
import {observer} from "mobx-react-lite";
import {Link} from "react-router-dom";
import DropdownMenu from "../components/Navigation/DropdownMenu"
import {useOnHoverOutside} from "../hooks/useOnHoverOutside";
import {fetchCollection} from "../http/collectionAPI";
import {fetchTypeWithSection} from "../http/typeAPI";

const NavBar = observer(() => {
    const {user, collection, type} = useContext(Context);

    const dropdownRefOne = useRef(null);
    const [isMenuDropDownOpenOne, setMenuDropDownOpenOne] = useState(false);

    const dropdownRefTwo = useRef(null);
    const [isMenuDropDownOpenTwo, setMenuDropDownOpenTwo] = useState(false);

    const dropdownRefThree = useRef(null);
    const [isMenuDropDownOpenThree, setMenuDropDownOpenThree] = useState(false);

    const closeHoverMenuOne = () => {
        setMenuDropDownOpenOne(false);
    };

    useOnHoverOutside(dropdownRefOne, closeHoverMenuOne);
    const closeHoverMenuTwo = () => {
        setMenuDropDownOpenTwo(false);
    };

    useOnHoverOutside(dropdownRefTwo, closeHoverMenuTwo);

    const closeHoverMenuThree = () => {
        setMenuDropDownOpenThree(false);
    };

    useOnHoverOutside(dropdownRefThree, closeHoverMenuThree);

    const logOut = () => {
        user.setIsUser({})
        user.setIsAuth(false)
        localStorage.removeItem('token')
        window.location.reload();
    }

    const [count, useCount] = useState(0)

    useEffect(() => {
        fetchCollection().then(data => collection.setCollection(data))
    }, [collection])

    useEffect(() => {
        fetchTypeWithSection().then(data => type.setTypeWithSection(data))
    }, [type])

    return (
        <div className="w-full flex justify-center bg-white drop-shadow-lg h-[70px] py-[6px] z-10">
            <div className="w-8/12 h-full flex items-center gap-8">
                <div className="h-full py-2">
                    <Link className="h-full" rel="noopener noreferer" to={HOME_ROUTE}>
                        <img loading="lazy"
                            className="h-full object-contain"
                            src={logo}
                            alt=""
                        />
                    </Link>
                </div>
                <div className="h-full flex items-center text-[1.2rem] gap-8">
                    <Link to='#' className="text-black no-underline">Новости</Link>
                    <Link to={PUBLIC_SECTION_SOUVENIR_PRODUCTS_ROUTE} className="text-black no-underline">Сувенирная продукция</Link>
                    <Link to={PUBLIC_SECTION_PRINTING_CENTER_ROUTE} className="text-black no-underline">Полиграфический центр</Link>
                    <Link to={PUBLIC_CONTACT} className="text-black no-underline">Контакты</Link>
                    {/*<Link to={PUBLIC_PRODUCT_ROUTE} className="text-black no-underline">Все товары</Link>
                    <div className="relative h-full text-[1.2rem] flex items-center" ref={dropdownRefOne}>
                        <button
                            className="text-black bg-white border-none text-[1.2rem] p-0 m-0 cursor-pointer"
                            onMouseOver={() => setMenuDropDownOpenOne(true)} //use mouseover event to show dropdown
                        >
                            Коллекции
                        </button>

                        {isMenuDropDownOpenOne &&
                            <DropdownMenu>
                                {collection.collection.map(data =>
                                    <Link
                                        key={data.id}
                                        to={PUBLIC_COLLECTIONS_ROUTE + "/" + data.url}
                                        className="no-underline text-black"
                                    >
                                        {data.name}
                                    </Link>
                                )}
                            </DropdownMenu>
                        }
                    </div>
                    <div className="relative h-full text-[1.2rem] flex items-center" ref={dropdownRefTwo}>
                        <button
                            className="text-black bg-white border-none text-[1.2rem] p-0 m-0 cursor-pointer"
                            onMouseOver={() => setMenuDropDownOpenTwo(true)} //use mouseover event to show dropdown
                        >
                            Одежда
                        </button>

                        {isMenuDropDownOpenTwo &&
                            <DropdownMenu>
                                <Link
                                    to={PUBLIC_SECTION_SOUVENIR_PRODUCTS_ROUTE}
                                    className="no-underline text-black"
                                >
                                    Вся одежда
                                </Link>
                                {type.typeWithSection[0].section.map(data =>
                                    <Link
                                        key={data.id}
                                        to={'/section/' + data.url}
                                        className="no-underline text-black"
                                    >
                                        {data.name}
                                    </Link>
                                )}
                            </DropdownMenu>
                        }
                    </div>
                    <div className="relative h-full text-[1.2rem] flex items-center" ref={dropdownRefThree}>
                        <button
                            className="text-black bg-white border-none text-[1.2rem] p-0 m-0 cursor-pointer"
                            onMouseOver={() => setMenuDropDownOpenThree(true)} //use mouseover event to show dropdown
                        >
                            Аксессуары
                        </button>

                        {isMenuDropDownOpenThree &&
                            <DropdownMenu>
                                <Link
                                    to={PUBLIC_SECTION_PRINTING_CENTER_ROUTE}
                                    className="no-underline text-black"
                                >
                                    Все аксессуары
                                </Link>
                                {type.typeWithSection[1].section.map(data =>
                                    <Link
                                        key={data.id}
                                        to={'/section/' + data.url}
                                        className="no-underline text-black"
                                    >
                                        {data.name}
                                    </Link>
                                )}
                            </DropdownMenu>
                        }
                    </div>
                    <Link to={PUBLIC_CONTACT} className="text-black no-underline">Контакты</Link>*/}
                </div>
                {user.isAuth ?
                    <div className="flex items-center justify-center gap-3 h-[54px] text-[1.2rem] ml-auto">
                        <Link to={ADMIN_ROUTE} className="cursor-pointer text-black">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"/>
                            </svg>
                        </Link>
                        <button className="cursor-pointer text-black border-0 bg-transparent" onClick={() => logOut()}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"/>
                            </svg>
                        </button>
                    </div>
                    :
                    null
                }
            </div>
        </div>
    );
});

export default NavBar;