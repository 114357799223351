import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";

const FilterProduct = observer(({sections, selectedSection, onSectionSelect}) => {
    const [isFilterSelected, setIsFilterSelected] = useState(false);

    useEffect(() => {
        setIsFilterSelected(!!selectedSection);
    }, [selectedSection]);

    const clearFilter = () => {
        onSectionSelect(null);
    };
    return (
        <div className="w-full flex flex-wrap gap-3">
            {sections.map((section) => (
                <button
                    key={section.id}
                    className={`bg-transparent border-gray-300 border-solid border-[1px] rounded-full p-2 text-[1rem] cursor-pointer font-normal ${
                        section === selectedSection
                            ? "bg-gray-200"
                            : ""
                    }`}
                    onClick={() => onSectionSelect(section)}
                >
                    {section.name}
                </button>
            ))}
            {isFilterSelected && (
                <button
                    className="ml-auto bg-white shadow-lg border-gray-300 border-solid border-[1px] rounded-full p-2 text-[1rem] cursor-pointer font-normal flex items-center gap-2"
                    onClick={clearFilter}
                >
                    Очистить фильтр
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                         stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                </button>
            )}
        </div>
    );
});

export default FilterProduct;