import React, {useContext, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {Link, useParams} from "react-router-dom";
import {SIZE_ROUTE} from "../../../utils/consts";
import {Toast} from "flowbite-react";
import {useForm} from "react-hook-form";
import {editSize, fetchOneSize} from "../../../http/sizeAPI";
import {Context} from "../../../index";


const Edite = observer(() => {
    const {size} = useContext(Context)

    const {id} = useParams()

    const {register, handleSubmit, setValue, formState: {errors}} = useForm();

    useEffect(() => {
        fetchOneSize(id).then(data => size.setAdminSize(data))
    }, [id])

    useEffect(() => {
        setValue("id", size.adminSize.id);
        setValue("name", size.adminSize.name);
    }, [size.adminSize]);

    const onSubmit = async (data) => {
        try {
            const formData = new FormData();
            formData.append('id', data.id)
            formData.append('name', data.name)
            let sizeData = await editSize(formData)
            size.setAdminSize(sizeData)
        } catch (e) {
            alert(e.response)
        }
    }
    return (
        <div
            className="flex flex-col gap-5 rounded-md border border-neutral-200 border-solid rounded-lg shadow-lg p-[24px]">
            <div className="flex items-center justify-between">
                <h1 className="text-[1.3rem] m-0">Создание типа</h1>
                <Link
                    to={SIZE_ROUTE}
                    className="flex items-center border-0 text-white text-[0.875rem] bg-[#111111] py-[12px] px-[24px] rounded-[50px] no-underline"
                >
                    Назад
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"/>
                    </svg>
                </Link>
            </div>
            {errors.name?.type === 'required'
                ?
                <div className="flex flex-col gap-4 absolute top-[90px] right-[24px] z-10">
                    {errors.name?.type === 'required' &&
                        <Toast className="w-auto">
                            <div
                                className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                </svg>

                            </div>
                            <div className="mx-3 text-sm font-normal">
                                Заполните поле "Название типа"
                            </div>
                            <Toast.Toggle
                                className="flex item-center justify-center outline-none outline-0 border-none"/>
                        </Toast>
                    }
                </div>
                :
                <></>
            }
            <form
                className="flex flex-col gap-4 border border-gray-200 border-solid rounded-mb p-[12px]"
                onSubmit={handleSubmit(onSubmit)}
            >
                <input
                    type="hidden"
                    {...register("id", {required: true})}
                    aria-invalid={errors.id ? "true" : "false"}
                />
                <h3 className="m-0">Форма создания типа</h3>
                <hr className="w-full h-[1px] bg-black"/>

                <div className="w-full flex items-center gap-3">
                    <label htmlFor="name" className="w-1/5 text-[1.2rem]">Название типа</label>
                    <input
                        className="w-full rounded-md outline-none"
                        id="name"
                        type="text"
                        name="name"
                        {...(register('name', {required: true}))}
                        aria-invalid={errors.name ? "true" : "false"}
                    />
                </div>
                <hr className="w-full h-[1px] bg-black"/>

                <button
                    type="submit"
                    className="inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-[#707070] px-4 py-2 text-base font-medium text-white shadow-sm cursor-pointer no-underline hover:bg-[#989898]"
                >
                    Изменить
                </button>
            </form>
        </div>
    );
});

export default Edite;