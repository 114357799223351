import {$authHost, $host} from "./index";

export const createType = async (formData) => {
    const {data} = await $authHost.post('api/type/create', formData)
    return data;
}
export const editType = async (formData) => {
    const {data} = await $authHost.post('api/type/edit', formData)
    return data;
}
export const delType = async (id) => {
    const {data} = await $authHost.get('api/type/del/'+ id)
    return data;
}
export const fetchType = async () => {
    const {data} = await $host.get('api/type')
    return data;
}
export const fetchTypeWithSection = async () => {
    const {data} = await $host.get('api/type/with-section')
    return data;
}
export const fetchTypeWithSectionByProduct = async (id) => {
    const {data} = await $host.get('api/type/withSectionByProduct/'+ id)
    return data;
}
export const fetchOneType = async (id) => {
    const {data} = await $host.get('api/type/' + id)
    return data;
}