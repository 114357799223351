import React, { useContext, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import { useParams } from "react-router-dom";
import { fetchOneCollectionWithUrlByProduct } from "../../http/collectionAPI";
import ProductList from "../../components/AllProduct/ProductList";

const OneCollection = observer(() => {
    const { collection } = useContext(Context);
    const { url } = useParams();

    useEffect(() => {
        fetchOneCollectionWithUrlByProduct(String(url)).then(data => collection.setSelectedCollection(data));
    }, [url]);

    const selectedCollection = collection.selectedCollection.collection;
    const products = collection.selectedCollection.products;

    return (
        <div className="flex flex-col gap-5">
            <h2 className="">Коллекция «{selectedCollection ? selectedCollection.name : null}»</h2>
            {products ?
                <ProductList products={products}/>
                :
                null
            }
        </div>
    );
});

export default OneCollection;
