import React, {useContext, useEffect} from 'react';
import carousel1 from "../../image/carousel-1.svg";
import {Link} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import {fetchSection} from "../../http/sectionAPI";

const SubMenuCard = observer(() => {
    const {section} = useContext(Context)

    useEffect(() => {
        fetchSection().then(data => section.setSection(data))
    }, [section])

    return (
        <div className="grid grid-cols-6 gap-5">
            {section.section.slice(0, 6).map(data =>
                <Link to="" key={data.id} className="col-span-1 flex flex-col gap-3 items-center no-underline hover:scale-105 transition-all duration-300 transform">
                    <div className="w-full h-[240px] flex items-center justify-center bg-gray-200">
                        <img
                            className="w-full object-cover"
                            src={data.image ? process.env.REACT_APP_API_URL + 'section/' + data.image : carousel1}
                            alt=""
                        />
                    </div>
                    <h3 className="text-[#707070] m-0 font-light">{data.name}</h3>
                </Link>
            )}
        </div>
    );
});

export default SubMenuCard;