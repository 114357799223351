import {makeAutoObservable} from "mobx";

export default class ProductStore {
    constructor() {
        this._product = []
        this._productWithSection = []
        this._selectedProduct = {};
        this._chartsProduct = {};
        this._arrivalsProduct = {};
        this._adminProduct = {};
        this._selectedAdminProduct = {};
        makeAutoObservable(this);
    }

    setProduct(product) {
        this._product = product;
    }
    setProductWithSection(product) {
        this._productWithSection = product;
    }

    setSelectedProduct(product) {
        this._selectedProduct = product
    }

    setChartsProduct(product) {
        this._chartsProduct = product
    }

    setArrivalsProduct(product) {
        this._arrivalsProduct = product
    }

    setAdminProduct(product) {
        this._adminProduct = product
    }

    setSelectedAdminProduct(product) {
        this._selectedAdminProduct = product
    }

    get product() {
        return this._product;
    }

    get selectedProduct() {
        return this._selectedProduct;
    }

    get productWithSection() {
        return this._productWithSection;
    }

    get chartsProduct() {
        return this._chartsProduct;
    }

    get arrivalsProduct() {
        return this._arrivalsProduct;
    }

    get adminProduct() {
        return this._adminProduct;
    }

    get selectedAdminProduct() {
        return this._selectedAdminProduct;
    }
}